import { put, takeEvery } from "redux-saga/effects";
import { resetAreas } from "../slices/areaSlice";
import { authenticate, deauthenticate } from "../slices/authSlice";
import { resetGeoservers } from "../slices/geoserverSlice";
import handleAreas from "./areas";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../config/aws";

/**
 * The sagas to call when a user logs in
 */
function* handleLogin() {
  yield handleAreas();
  yield put(authenticate());
}

/**
 * And the sagas to call when a user logs out
 */
function* handleLogout() {
  yield put(resetGeoservers());
  yield put(resetAreas());
  yield put(deauthenticate());

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser !== null) {
    yield cognitoUser.signOut();
  }
}

/**
 * Connect the "fake" actions to the login and logout sagas
 */
export default function* sagas() {
  yield takeEvery("auth/processLogin", handleLogin);
  yield takeEvery("auth/processLogout", handleLogout);
}
