import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { find } from "lodash";
import { RootState } from "../store";

/**
 * The shape of a area
 */
export interface Area {
  id: number;
  name: string;
  nameSlug: string;
  company: string;
  companySlug: string;
  geoserverIds: Array<number>;
}

/**
 * The shape of how all the areas are stored
 * activeId is last viewed area
 */
interface AreaState {
  byId: {
    [id: number]: Area;
  };
  allIds: Array<number>;
  activeId: number | null;
}

/**
 * How the area state should be before data is added
 */
const initialState: AreaState = {
  byId: {},
  allIds: [],
  activeId: null,
};

/**
 * The slice containing the reducers
 */
export const areaSlice = createSlice({
  name: "area",
  initialState: initialState,
  reducers: {
    /**
     * Reducer for adding a area, checks if the area has already been added before adding
     */
    addArea: (state, action: PayloadAction<Area>) => {
      if (state.allIds.indexOf(action.payload.id) === -1) {
        state.allIds.push(action.payload.id);
        state.byId[action.payload.id] = action.payload;

        if (state.activeId === null) {
          state.activeId = action.payload.id;
        }
      }
    },
    /**
     * Reducer for changing which area is active
     */
    setActiveArea: (state, action: PayloadAction<Area>) => {
      state.activeId = action.payload.id;

      return state;
    },
    /**
     * Reducer for setting the state back to its initial values
     */
    resetAreas: () => {
      return initialState;
    },
  },
});

/**
 * Actions for the above defined reducers
 */
export const { addArea, setActiveArea, resetAreas } = areaSlice.actions;

/**
 * Selector for retrieving all the areas
 */
export const selectAllAreas = (state: RootState) => {
  const areas: Array<Area> = [];

  for (const id of state.area.allIds) {
    areas.push(state.area.byId[id]);
  }

  return areas;
};

/**
 * Selector for getting the currently active area
 */
export const selectActiveArea = (state: RootState) => {
  if (state.area.activeId !== null) {
    return state.area.byId[state.area.activeId];
  }

  return null;
};

/**
 * Selector for getting a area through its id
 */
export const selectAreaById = (state: RootState, areaId: number) => {
  if (state.area.allIds.includes(areaId)) {
    return state.area.byId[areaId];
  }

  return null;
};

/**
 * Selector for finding a area based on its own slug and a slug for a company
 */
export const selectAreaByCompanyAndAreaSlugs = (
  state: RootState,
  companySlug: string,
  areaSlug: string
) => {
  const area = find(state.area.byId, {
    companySlug: companySlug,
    nameSlug: areaSlug,
  });

  if (area !== undefined) {
    return area;
  }

  return null;
};

export default areaSlice.reducer;
