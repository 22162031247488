import dayjs from "dayjs";
import { Tile } from "ol";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import TileState from "ol/TileState";
import { Geoserver } from "../../slices/geoserverSlice";

/**
 * Create and return a new WMS layer
 *
 * @param geoserver The Geoserver to create the WMS layer for
 * @param layerName The name of the layer to create
 * @param time The time series for the requested layer
 */
export default function createWMSLayer(
  geoserver: Geoserver,
  layerName: string,
  time: string | undefined
) {
  interface Params {
    LAYERS: string;
    TILED: boolean;
    time?: string;
    FORMAT: string;
    WIDTH: number;
    HEIGHT: number;
  }

  const params: Params = {
    LAYERS: layerName,
    TILED: true,
    FORMAT: "image/vnd.jpeg-png",
    WIDTH: 256,
    HEIGHT: 256,
  };

  let zIndex = undefined;

  if (time !== undefined) {
    params["time"] = time;
  }

  if (layerName === "imagery") {
    if (time !== undefined) {
      zIndex = Number(dayjs(time).format("YYYYMMDD"));
    }
  } else {
    zIndex = 29999999;
  }

  return new TileLayer({
    source: new TileWMS({
      url: `${geoserver.server}${geoserver.namespace}/wms`,
      params,
      serverType: "geoserver",
      crossOrigin: "anonymous",
      tileLoadFunction: (tile, src) => WMSTileLoad(tile, src, geoserver),
    }),
    extent: geoserver.bbox,
    zIndex: zIndex,
  });
}

/**
 * Function to attach the Geoserver credentials when loading a tile
 *
 * @param tile The tile which is requested
 * @param src The source to load the tile from
 * @param geoserver The geoserver instance for the auth data
 */
function WMSTileLoad(tile: Tile, src: string, geoserver: Geoserver) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", src);
  xhr.responseType = "arraybuffer";
  xhr.setRequestHeader(
    "Authorization",
    "Basic " + window.btoa(geoserver.username + ":" + geoserver.password)
  );

  xhr.addEventListener("loadend", function () {
    let data = this.response;

    if (data !== undefined) {
      const arrayBufferView = new Uint8Array(data);
      const blob = new Blob([arrayBufferView], {
        type: "image/jpeg",
      });
      const urlCreator = window.URL || (window as any).webkitURL;
      (tile as any).getImage().src = urlCreator.createObjectURL(blob);
    } else {
      tile.setState(TileState.ERROR);
    }
  });

  xhr.addEventListener("error", function () {
    tile.setState(TileState.ERROR);
  });

  xhr.send();
}
