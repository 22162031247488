export const poolData = {
  UserPoolId: "eu-west-1_Hstdmj1G3",
  ClientId: "22ohfnkdt8g6vucofooc8sat2k",
};

export const GEOSERVER_META_URL =
  "https://oy8bh4enef.execute-api.eu-west-1.amazonaws.com/prod";

export const GRAPHQL_URL =
  "https://graphql.prod.ml-platform.opusinsights.nl/prod/graphql";

export const GEO_TOOLBOX_URL =
  "https://toolbox.opusinsights.nl/prod";
