import {
  Alert,
  Button,
  MinimalCard,
  TextField,
  Typography,
} from "@opusinsights/ui";
import React, { useState } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../../config/aws";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

// The shape of the data and the yup validation used in the form
interface NewPasswordData {
  currentPassword: string;
  newPassword: string;
}

const schema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().required(),
});

/**
 * Component for allowing a user to update their password
 */
export default function NewPassword() {
  const { t, i18n } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ currentPassword, newPassword }) => {
    setSubmitted(true);
    setFormError("");
    setFormSuccess("");

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err: any) => {
        if (err) {
          console.log(err);
        } else {
          cognitoUser.changePassword(currentPassword, newPassword, (err) => {
            if (err) {
              setFormError(err.message);
            } else {
              setFormSuccess(t("settings.Your password has been changed"));
            }

            setSubmitted(false);
          });
        }
      });
    } else {
      setSubmitted(false);
      setFormError(t("settings.Unable to change your password"));
    }
  });

  let currentPasswordError = undefined;
  if (errors.currentPassword !== undefined) {
    if (i18n.exists(`errors.${errors.currentPassword.message}`)) {
      currentPasswordError = t(`errors.${errors.currentPassword.message}`);
    } else {
      currentPasswordError = errors.currentPassword.message;
    }
  }

  let newPasswordError = undefined;
  if (errors.newPassword !== undefined) {
    if (i18n.exists(`errors.${errors.newPassword.message}`)) {
      newPasswordError = t(`errors.${errors.newPassword.message}`);
    } else {
      newPasswordError = errors.newPassword.message;
    }
  }

  return (
    <MinimalCard>
      <Typography variant="h1">{t("settings.Change your password")}</Typography>

      <form onSubmit={onSubmit}>
        <TextField
          id="password-curent"
          label={t("settings.Your Current Password")}
          register={register("currentPassword")}
          width="medium"
          password
        />

        {currentPasswordError !== undefined && (
          <Alert type="error" width="medium">
            {currentPasswordError}
          </Alert>
        )}

        <TextField
          id="password-new"
          label={t("settings.Your New Password")}
          register={register("newPassword")}
          width="medium"
          password
        />

        {newPasswordError !== undefined && (
          <Alert type="error" width="medium">
            {newPasswordError}
          </Alert>
        )}

        {formError !== "" && (
          <Alert type="error" width="medium">
            {formError}
          </Alert>
        )}

        {formSuccess !== "" && (
          <Alert type="success" width="medium">
            {formSuccess}
          </Alert>
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitted}
        >
          {t("settings.Save")}
        </Button>
      </form>
    </MinimalCard>
  );
}
