import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { find } from "lodash";
import { RootState } from "../store";

/**
 * The shape of the styles for a layer
 */
export interface Style {
  name: string;
  title: string | null;
}

/**
 * Shape of a individual layer
 */
export interface GeoserverLayerByTime {
  name: string;
  keywords: Array<string>;
  styles: Array<string>;
}

/**
 * Shape of the collection of layers for a geoserver instance
 */
export interface GeoserverLayers {
  byTime: {
    [time: string]: Array<GeoserverLayerByTime>;
  };
  allTimes: Array<string>;
}

/**
 * Shape of a geoserver instance for a specific area
 */
export interface Geoserver {
  id: number;
  areaId: number;
  username: string;
  password: string;
  namespace: string;
  server: string;
  bbox: [number, number, number, number];
  layers: GeoserverLayers;
}

/**
 * Shape of top level state for the geoservers
 */
interface InitialGeoserverState {
  byId: { [id: number]: Geoserver };
  allIds: Array<number>;
}

/**
 * How the state should be before data is added
 */
const initialState: InitialGeoserverState = {
  byId: {},
  allIds: [],
};

/**
 * The slice containing the reducers
 */
export const geoserverSlice = createSlice({
  name: "geoserver",
  initialState: initialState,
  reducers: {
    /**
     * Reducer for adding a geoserver, checks if the geoserver has already been added before adding
     */
    addGeoserver: (state, action: PayloadAction<Geoserver>) => {
      if (state.allIds.indexOf(action.payload.id) === -1) {
        state.allIds.push(action.payload.id);
        state.byId[action.payload.id] = action.payload;
      }
    },
    /**
     * Resets the state back to the default
     */
    resetGeoservers: () => {
      return initialState;
    },
  },
});

/**
 * Actions for the above defined reducers
 */
export const { addGeoserver, resetGeoservers } = geoserverSlice.actions;

/**
 * Selector for getting all geoservers
 */
export const selectAllGeoservers = (state: RootState) => {
  const geoservers: Array<Geoserver> = [];

  for (const id of state.geoserver.allIds) {
    geoservers.push(state.geoserver.byId[id]);
  }

  return geoservers;
};

/**
 * Selector for getting a geoserver based on a company and area slug
 */
export const selectGeoserversByCompanyAndAreaSlugs = (
  state: RootState,
  companySlug: string,
  areaSlug: string
) => {
  const geoservers: Array<Geoserver> = [];

  // Get the area from the area state
  const area = find(state.area.byId, {
    companySlug: companySlug,
    nameSlug: areaSlug,
  });

  if (area !== undefined) {
    for (const id of area.geoserverIds) {
      geoservers.push(state.geoserver.byId[id]);
    }
  }

  return geoservers;
};

export default geoserverSlice.reducer;
