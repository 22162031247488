import OLMap from "ol/Map";
import { State } from "../interfaces";
import { getLayerIds } from "./LayerUtils";

/**
 * Adds all the layers from a given time to the map
 *
 * @param state The state of the map
 * @param olMap The OpenLayers map instance
 */
export default function ActivateAllLayers(
  state: Readonly<State>,
  olMap: OLMap,
  time: string
): void {
  for (const layerName of state.layers.active) {
    const layerIds = getLayerIds(state, layerName, time);

    for (const layerId of layerIds) {
      const layer = state.layers.byId[layerId].layer;

      if (layer !== undefined) {
        olMap.addLayer(layer);
      }
    }
  }

  return;
}
