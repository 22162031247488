import { LoginLayout } from "@opusinsights/ui";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../../config/logo";
import { selectIsAuthenticated } from "../../slices/authSlice";
import EmailForm from "./EmailForm";
import NewPasswordForm from "./NewPasswordForm";

/**
 * Parent component for resetting a accounts password, show either the email form or the password form
 */
function PasswordReset() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [resetEmail, setResetEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  if (resetEmail !== undefined) {
    return (
      <LoginLayout
        logo={LOGO}
        form={<NewPasswordForm resetEmail={resetEmail} />}
      />
    );
  }

  return (
    <LoginLayout
      logo={LOGO}
      form={<EmailForm setResetEmail={setResetEmail} />}
    />
  );
}

export default PasswordReset;
