import { createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

/**
 * The auth slice containing reducer for authenticating and deauthenticating the user
 * Auth logic for interaction with AWS services is done through cognito
 */
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
  },
  reducers: {
    authenticate: (state) => {
      state.authenticated = true;
    },
    deauthenticate: (state) => {
      state.authenticated = false;
    },
  },
});

/**
 * Actions for the above defined reducers
 */
export const { authenticate, deauthenticate } = authSlice.actions;

/**
 * "fake" actions for the sagas to hook into
 */
export const processLogin = createAction("auth/processLogin");
export const processLogout = createAction("auth/processLogout");

/**
 * Simple selector to see if the user is authenticated
 */
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.authenticated;

export default authSlice.reducer;
