import { put } from "redux-saga/effects";
import slugify from "slugify";
import { addArea, Area } from "../slices/areaSlice";
import handleGeoserver from "./geoserver";
import * as Sentry from "@sentry/react";
import { APIArea, APICapabilities } from "./areas";

/*
 * Function for adding a area coming back from the graphql api
 *
 * @param area The area data to process
 * @param capabilities The capabilities from the aggregation api
 */
export default function* handleArea(
  area: APIArea,
  capabilities: APICapabilities | null
) {
  const addedGeoservers: number[] = [];

  for (let geoserver of area.geoservers) {
    try {
      yield handleGeoserver(geoserver, Number(area.id), capabilities);
      addedGeoservers.push(Number(geoserver.id));
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  if (addedGeoservers.length > 0) {
    const storeArea: Area = {
      id: Number(area.id),
      name: area.name,
      nameSlug: slugify(area.name),
      company: area.company.name,
      companySlug: slugify(area.company.name),
      geoserverIds: addedGeoservers,
    };

    yield put(addArea(storeArea));
  }
}
