import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../config/aws";
import { deauthenticate } from "../slices/authSlice";
import store from "../store";

/**
 * Simple function to get the users Cognito token (if they have a active session)
 *
 * @returns The Cognito token
 * @throws Error if there is no valid token
 */
export default function getCognitoToken() {
  return new Promise((resolve) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err: any, data: any) => {
        if (err) {
          store.dispatch(deauthenticate);
          resolve(false);
        } else {
          resolve(data.getIdToken().getJwtToken());
        }
      });
    } else {
      store.dispatch(deauthenticate);
      resolve(false);
    }
  });
}
