import produce from "immer";
import { Overlay } from "ol";
import GeometryType from "ol/geom/GeometryType";
import Draw from "ol/interaction/Draw";
import OLMap from "ol/Map";
import { unByKey } from "ol/Observable";
import OverlayPositioning from "ol/OverlayPositioning";
import VectorSource from "ol/source/Vector";
import { getLength } from "ol/sphere";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { State } from "../interfaces";

function formatLength(line: any) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = `${Math.round((length / 1000) * 100) / 100} km`;
  } else {
    output = `${Math.round(length * 100) / 100} m`;
  }
  return output;
}

function createHelpTooltip() {
  let helpTooltipElement = document.getElementById("measurement-help-tooltip");

  if (helpTooltipElement !== null && helpTooltipElement.parentNode !== null) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }

  helpTooltipElement = document.createElement("div");
  helpTooltipElement.className = "ol-tooltip hidden";
  helpTooltipElement.id = "measurement-help-tooltip";
  const helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: OverlayPositioning["CENTER_LEFT"],
  });

  return { overlay: helpTooltip, element: helpTooltipElement };
}

function createMeasureTooltip() {
  let measureTooltipElement = document.getElementById("measurement-tooltip");

  if (
    measureTooltipElement !== null &&
    measureTooltipElement.parentNode !== null
  ) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }

  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
  const measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: OverlayPositioning["BOTTOM_CENTER"],
    stopEvent: false,
    insertFirst: false,
  });

  return { overlay: measureTooltip, element: measureTooltipElement };
}

export default function AddMeasurementInteraction(
  state: Readonly<State>,
  drawSource: VectorSource<any>,
  olMap: OLMap
): State {
  const measurementDraw = new Draw({
    source: drawSource,
    type: GeometryType["LINE_STRING"],
    style: new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)",
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
      }),
    }),
  });

  olMap.addInteraction(measurementDraw);

  const measureTooltip = createMeasureTooltip();
  olMap.addOverlay(measureTooltip.overlay);

  const helpTooltip = createHelpTooltip();
  olMap.addOverlay(helpTooltip.overlay);

  let listener: any;
  let sketch: any;

  measurementDraw.on("drawstart", (evt: any) => {
    sketch = evt.feature;
    let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on("change", function (evt: any) {
      drawSource.clear();
      const geom = evt.target;
      let output = formatLength(geom);
      tooltipCoord = geom.getLastCoordinate();
      measureTooltip.element.innerHTML = output;
      measureTooltip.overlay.setPosition(tooltipCoord);
    });
  });

  measurementDraw.on("drawend", function () {
    measureTooltip.element.className = "ol-tooltip ol-tooltip-static";
    measureTooltip.overlay.setOffset([0, -7]);
    sketch = null;
    createMeasureTooltip();
    unByKey(listener);
  });

  const nextState = produce(state, (draftState) => {
    draftState.interactions.measurement.draw = measurementDraw;
    draftState.interactions.measurement.measureTooltip = measureTooltip.overlay;
    draftState.interactions.measurement.helpTooltip = helpTooltip.overlay;
  });

  return nextState;
}
