import { MinimalCard, Typography } from "@opusinsights/ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { poolData } from "../../config/aws";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

/**
 * Component which shows some general info such as the current users email address and the app version
 */
export default function GeneralInformation() {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((sessionErr: any) => {
        if (!sessionErr) {
          cognitoUser.getUserAttributes((err, result) => {
            if (!err && result !== undefined) {
              for (const attribute of result) {
                if (attribute.getName() === "email") {
                  setUserEmail(attribute.getValue());
                }
              }
            }
          });
        }
      });
    }
  }, []);

  return (
    <MinimalCard marginTop="medium">
      <Typography variant="h1">{t("settings.General Information")}</Typography>

      {userEmail !== undefined && (
        <Typography variant="p">
          {t("settings.Your Email Address")}: {userEmail}
        </Typography>
      )}
      {process.env.REACT_APP_VERSION !== undefined && (
        <Typography variant="p">
          {t("settings.Application Version")}: {process.env.REACT_APP_VERSION}
        </Typography>
      )}
    </MinimalCard>
  );
}
