import { enableAllPlugins } from "immer";
import "ol/ol.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "tippy.js/dist/tippy.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./components/App/App";
import Start from "./components/Start/Start";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "@opusinsights/ui/dist/ui.cjs.development.css";
import { SENTRY_DSN, SENTRY_SAMPLE_RATE } from "./config/sentry";
import { Toaster } from "react-hot-toast";

enableAllPlugins();

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Toaster position="bottom-center" />
    <Provider store={store}>
      <Start>
        <App />
      </Start>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
