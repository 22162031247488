import { Card } from "@opusinsights/ui";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import SingleAreaMap from "../../../map/SingleAreaMap";
import { Events } from "../../../map/SingleAreaMap/interfaces";
import { Area } from "../../../slices/areaSlice";

/**
 * The props expected by the GlobalStatistics component
 */
interface GlobalStatisticsProps {
  map: SingleAreaMap;
  area: Area;
}

type Statistics = { [name: string]: any };
type StatisticsPerLayerAndType = {
  [layerName: string]: { [statisticsType: string]: number };
};

/**
 * Sort the raw statistics into buckets based on the layer names
 *
 * @param statistics The raw statistics
 * @returns The statistics sorted by layer name and statistic type
 */
function sortStatistics(statistics: Statistics): StatisticsPerLayerAndType {
  const statisticsPerLayerAndType: StatisticsPerLayerAndType = {};

  for (const [name, item] of Object.entries(statistics)) {
    if (name.includes("_")) {
      // Pull the type and layer name out of the name variable
      // e.g. area_treecount is split into the type (area) and the layer name (treecount)
      const statisticsType = name.split("_")[0];
      const layerName = name.split("_").splice(1).join("_");

      // We need a layer name of course but we also only want to show the statistics if it as a value greater than 0
      if (layerName !== "" && Number(item) !== 0) {
        if (statisticsPerLayerAndType[layerName] === undefined) {
          statisticsPerLayerAndType[layerName] = {};
        }

        statisticsPerLayerAndType[layerName][statisticsType] = Number(item);
      }
    }
  }

  return statisticsPerLayerAndType;
}

function formatSortedStatistics(
  statistics: StatisticsPerLayerAndType,
  t: TFunction<"translation", undefined>
): Statistics {
  const formatted: Statistics = {};

  for (const [layerName, layerStatistics] of Object.entries(statistics)) {
    // Format the values if both area and count are present
    if (
      layerStatistics.area !== undefined &&
      layerStatistics.count !== undefined
    ) {
      const total = (layerStatistics.area / 10000).toFixed(2);

      formatted[layerName] = t("statistics.Total Count And Area", {
        count: layerStatistics.count,
        total: total,
      });
    }

    // Format the area value if count is not present
    if (
      layerStatistics.area !== undefined &&
      layerStatistics.count === undefined
    ) {
      const total =
        layerName === "total"
          ? layerStatistics.area.toFixed(2)
          : (layerStatistics.area / 10000).toFixed(2);

      formatted[layerName] = t("statistics.Total Area Value", {
        total: total,
      });
    }

    // Format the count if the area is not present
    if (
      layerStatistics.count !== undefined &&
      layerStatistics.area === undefined
    ) {
      formatted[layerName] = t("statistics.Total Count", {
        count: layerStatistics.count,
      });
    }
  }

  return formatted;
}

/**
 * Component for showing the global statistics card in the sidebar
 */
function GlobalStatistics(props: GlobalStatisticsProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<Statistics>({});

  useEffect(() => {
    function parseStatistics(statistics: {
      data: Statistics;
      loading: boolean;
    }) {
      const sorted = sortStatistics(statistics["data"]);
      const formatted = formatSortedStatistics(sorted, t);

      setLoading(statistics.loading);
      setStatistics(formatted);
    }

    parseStatistics(props.map.getStatistics());

    props.map.emitter.on(Events["statistics:global_loaded"], () => {
      parseStatistics(props.map.getStatistics());
    });

    props.map.emitter.on(Events["timeseries:changed"], () => {
      parseStatistics(props.map.getStatistics());
    });
  }, [props.map, t]);

  // Translate the statistics name
  function getNameTranslation(name: string): string {
    if (i18n.exists(`statistics.${name}`)) {
      return t(`statistics.${name}`);
    }

    if (i18n.exists(`layers.${name}`)) {
      return t(`layers.${name}`);
    }

    return upperFirst(name);
  }

  if (loading) {
    return (
      <Card
        title={t(`statistics.Global Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.Loading the statistics`)}</p>
      </Card>
    );
  }

  if (Object.keys(statistics).length === 0) {
    return (
      <Card
        title={t(`statistics.Global Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.There are no statistics to display`)}</p>
      </Card>
    );
  }

  return (
    <Card
      title={t(`statistics.Global Statistics`)}
      marginTop="medium"
      canBeMinimized={true}
    >
      <p>
        <strong>{t(`statistics.Area Name`)}</strong>: {props.area.name} /{" "}
        {props.area.company}
      </p>

      {Object.entries(statistics).map(([name, value], index) => (
        <p key={index}>
          <strong>{getNameTranslation(name)}</strong>: {value}
        </p>
      ))}
    </Card>
  );
}

export default GlobalStatistics;
