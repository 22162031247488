import { State } from "./interfaces";

/**
 * The state of the map before data has been added
 */
export const defaultState: State = {
  geoservers: {
    byId: {},
    allIds: [],
  },
  times: {
    allTimes: [],
    active: undefined,
    mostRecentForGeoserverIdByTime: {},
    mostRecentForGeoserverIdById: {},
  },
  layers: {
    byId: {},
    allIds: [],
    idsByTimeSeriesAndName: {},
    idsByTimeSeriesGeoserverIdAndName: {},
    active: [],
  },
  statistics: {
    byTimeGeoserverIdAndName: {},
    loading: true,
    drawnCache: undefined,
    clickCache: undefined,
  },
  interactions: {
    boxSelection: undefined,
    polygonSelection: undefined,
    measurement: {
      draw: undefined,
      measureTooltip: undefined,
      helpTooltip: undefined,
    },
  },
};
