import { Card, Sidebar, TextField } from "@opusinsights/ui";
import Fuse from "fuse.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Area, selectAreaById, setActiveArea } from "../../slices/areaSlice";
import store from "../../store";

/**
 * The props which the sidebar expects, a list of all the areas and the current active area
 */
interface OverviewSidebarProps {
  areas: Array<Area>;
  activeArea: Area | null;
}

// The fields fuse should search through
const fuseOptions = {
  keys: ["company", "name"],
};

/**
 * The sidebar component for listing all the selectable areas
 */
export default function OverviewSidebar(props: OverviewSidebarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fuse = new Fuse(props.areas, fuseOptions);
  const [areas, setAreas] = useState(props.areas);

  function handleSetActiveArea(areaId: number) {
    const area = selectAreaById(store.getState(), areaId);

    if (area !== null) {
      dispatch(setActiveArea(area));
      navigate(`/area/${area.companySlug}/${area.nameSlug}`);
    }
  }

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      setAreas(props.areas);
    } else {
      const searchResults = fuse.search(event.target.value);
      const newAreas: Array<Area> = [];

      for (const result of searchResults) {
        newAreas.push(result.item);
      }

      setAreas(newAreas);
    }
  }

  return (
    <Sidebar>
      <TextField
        id="sidebar-areas-search"
        label={t("overview.Search Areas")}
        placeholder={t("overview.Search Areas")}
        hiddenLabel={true}
        margin="small"
        onChange={handleSearch}
      />

      {areas.map((area) => (
        <Card
          title={area.name}
          active={props.activeArea !== null && area.id === props.activeArea.id}
          marginTop="medium"
          key={area.id}
          onClick={() => handleSetActiveArea(area.id)}
        >
          {area.company}
        </Card>
      ))}
    </Sidebar>
  );
}
