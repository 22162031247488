import { LoadingLayout, LoginLayout } from "@opusinsights/ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../../config/logo";
import { selectIsAuthenticated } from "../../slices/authSlice";
import LoginForm from "./LoginForm";
import NewPasswordForm from "./NewPasswordForm";

/**
 * Component for the login page, displays either the login form or the form for setting up a new password if needed
 */
function Login() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);

 const [authData, setAuthData] = useState({
    email: "",
    password: "",
  });
  const [loginSucceeded, setLoginSucceeded] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  if (loginSucceeded) {
    return <LoadingLayout />;
  }

  if (authData.email !== "" && authData.password !== "") {
    return (
      <LoginLayout
        logo={LOGO}
        form={
          <NewPasswordForm
            authData={authData}
            setLoginSucceeded={setLoginSucceeded}
          />
        }
      />
    );
  }

  return (
    <LoginLayout
      logo={LOGO}
      form={
        <LoginForm
          setAuthData={setAuthData}
          setLoginSucceeded={setLoginSucceeded}
        />
      }
    />
  );
}

export default Login;
