import produce from "immer";
import { State } from "../interfaces";
import OLMap from "ol/Map";
import VectorSource from "ol/source/Vector";

/**
 * Removes all interactions from the map
 *
 * @param state The state of the map
 * @param drawSource The source to which the interactions are drawn
 * @param olMap The OpenLayers map instance
 * @returns The state with the interactions removed
 */
export default function ClearInteractions(
  state: Readonly<State>,
  drawSource: VectorSource<any>,
  olMap: OLMap
): State {
  drawSource.clear();

  const nextState = produce(state, (draftState) => {
    if (state.interactions.boxSelection !== undefined) {
      olMap.removeInteraction(state.interactions.boxSelection);
      draftState.interactions.boxSelection = undefined;
    }

    if (state.interactions.polygonSelection !== undefined) {
      olMap.removeInteraction(state.interactions.polygonSelection);
      draftState.interactions.polygonSelection = undefined;
    }

    if (state.interactions.measurement.draw !== undefined) {
      olMap.removeInteraction(state.interactions.measurement.draw);
      draftState.interactions.measurement.draw = undefined;
    }

    if (state.interactions.measurement.helpTooltip !== undefined) {
      olMap.removeOverlay(state.interactions.measurement.helpTooltip);
      draftState.interactions.measurement.helpTooltip = undefined;
    }

    const helpTooltipElement = document.getElementById(
      "measurement-help-tooltip"
    );

    if (helpTooltipElement !== null && helpTooltipElement.parentNode !== null) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }

    if (state.interactions.measurement.measureTooltip !== undefined) {
      olMap.removeOverlay(state.interactions.measurement.measureTooltip);
      draftState.interactions.measurement.measureTooltip = undefined;
    }

    const measureTooltipElement = document.getElementById(
      "measurement-tooltip"
    );

    if (
      measureTooltipElement !== null &&
      measureTooltipElement.parentNode !== null
    ) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
  });

  return nextState;
}
