import axios from "axios";
import { isArray, merge } from "lodash";
import { GEO_TOOLBOX_URL } from "../../../config/aws";
import getCognitoToken from "../../../utils/getCognitoToken";
import { State } from "../interfaces";
import * as Sentry from "@sentry/react";
import { getGeoserverWorkspaces } from "../geoservers/GeoserverUtils";

type StatisticsReturn = {
  [time: string]: { [geoserverId: string]: { [name: string]: string } };
};

/**
 * Retrieves the statistics data from the toolbox lambda and stores it
 *
 * @param state The state of the map
 * @returns The updated state with the statistics data
 */
export default async function LoadStatistics(
  state: Readonly<State>
): Promise<StatisticsReturn> {
  let statistics: { [time: string]: { [name: string]: any } } = {};

  if (state.geoservers.allIds.length === 0) {
    return statistics;
  }

  const workspaces = getGeoserverWorkspaces(state);
  const layers = getLayers(state);

  const promises = [];
  for (const [geoserverId, workspace] of Object.entries(workspaces)) {
    promises.push(getStatistics(geoserverId, workspace, layers[geoserverId]));
  }

  const promiseResult = await Promise.all(promises);

  for (const result of promiseResult) {
    const allDates = getDates(result);
    const shaped = shapeStatisticsData(result, allDates);
    statistics = merge(statistics, shaped);
  }

  return statistics;
}

type StatisticsLayers = { [geoserverId: string]: Array<string> };

/**
 * Returns the layers associated with each geoserver
 *
 * @param state The state of the map
 * @returns A object of geoserverIds with the associated layers
 */
function getLayers(state: Readonly<State>): StatisticsLayers {
  const layers: StatisticsLayers = {};

  for (const geoserverId of state.geoservers.allIds) {
    layers[geoserverId] = [];

    for (const geoserverTime of state.geoservers.byId[geoserverId].layers
      .allTimes) {
      for (const geoserverLayer of state.geoservers.byId[geoserverId].layers
        .byTime[geoserverTime]) {
        if (layers[geoserverId].indexOf(geoserverLayer.name) === -1) {
          layers[geoserverId].push(geoserverLayer.name);
        }
      }
    }
  }

  return layers;
}

type StatisticsApiReturn = {
  [geoserverId: string]: {
    [name: string]: string | [{ [field: string]: string }];
  };
};

/**
 * Retrieves the statistics from the API for the given workspace and layers
 *
 * @param geoserverId The id of the geoserver to which the namespace belongs
 * @param workspace The name of the workspace
 * @param layers A array of layer names
 * @returns A object with the statistics returned from the API
 */
async function getStatistics(
  geoserverId: string,
  workspace: string,
  layers: Array<string>
): Promise<StatisticsApiReturn> {
  let flattenedLayers = "";
  for (const layer of layers) {
    flattenedLayers = `${flattenedLayers}&layernames=${layer}`;
  }

  try {
    const token: unknown = await getCognitoToken();

    const response = await axios({
      method: "get",
      url: `${GEO_TOOLBOX_URL}/aggregatestats?workspace=${workspace}&${flattenedLayers}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { [geoserverId]: response.data };
  } catch (err) {
    Sentry.captureException(err);
    return { [geoserverId]: {} };
  }
}

type StatisticsDates = Array<string>;

/**
 * Returns all the dates specified in the data
 *
 * @param statisticsData The statistics data
 * @returns A array of dates
 */
function getDates(statisticsData: StatisticsApiReturn): StatisticsDates {
  const allDates: StatisticsDates = [];

  for (const data of Object.values(statisticsData)) {
    for (const item of Object.values(data)) {
      if (isArray(item)) {
        for (const subitem of item) {
          if (allDates.indexOf(subitem.date) === -1) {
            allDates.push(subitem.date);
          }
        }
      }
    }
  }

  return allDates;
}

/**
 * Shapes the data returned from the API into how we like it
 *
 * @param statisticsData The statistics data
 * @param allDates All the time series present in the  statistics data
 * @returns The statistics shaped in the way we need it
 */
function shapeStatisticsData(
  statisticsData: StatisticsApiReturn,
  allDates: StatisticsDates
): StatisticsReturn {
  const shapedData: StatisticsReturn = {};

  // Set up the correct paths in the object
  for (const date of allDates) {
    shapedData[date] = {};

    for (const geoserverId of Object.keys(statisticsData)) {
      shapedData[date][geoserverId] = {};
    }
  }

  // Shape the data
  for (const [geoserverId, data] of Object.entries(statisticsData)) {
    for (const [itemName, itemValue] of Object.entries(data)) {
      if (isArray(itemValue)) {
        for (const subitem of itemValue) {
          shapedData[subitem.date][geoserverId][itemName] =
            subitem[Object.keys(subitem).filter((key) => key !== "date")[0]];
        }
      } else {
        for (const date of allDates) {
          for (const geoserverId of Object.keys(statisticsData)) {
            shapedData[date][geoserverId][itemName] = itemValue;
          }
        }
      }
    }
  }

  return shapedData;
}
