import { Header } from "@opusinsights/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { LOGO } from "../../config/logo";
import i18n from "../../i18n";

/**
 * The header component for the entire app
 */
export default function AppHeader() {
  const { t } = useTranslation();

  return (
    <Header
      logo={LOGO}
      language={t(`header.Language`)}
      languages={[
        { code: "en", name: "English" },
        { code: "fr", name: "Français" },
        { code: "es", name: "Spanish" },
      ]}
      selectedLanguage={i18n.language}
      onLanguageSelect={(code) => i18n.changeLanguage(code)}
    />
  );
}
