import Emittery from "emittery";
import produce from "immer";
import { forEach } from "lodash";
import { MousePosition } from "ol/control";
import { createStringXY } from "ol/coordinate";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { VectorSourceEvent } from "ol/source/Vector";
import OLMap from "ol/Map";
import { Vector as VectorSource } from "ol/source";
import OSM from "ol/source/OSM";
import { getArea } from "ol/sphere";
import View from "ol/View";
import Geometry from "ol/geom/Geometry";
import { Geoserver } from "../../slices/geoserverSlice";
import { defaultState } from "./defaults";
import AddGeoservers from "./geoservers/AddGeoservers";
import CenterOnGeoservers from "./geoservers/CenterOnGeoservers";
import QueryClickSelection from "./interactions/QueryClickSelection";
import QueryFeatureSelection from "./interactions/QueryFeatureSelection";
import { Events, LayerData, State } from "./interfaces";
import ActivateAllLayers from "./layers/ActivateAllLayers";
import AddLayer from "./layers/AddLayer";
import DeactivateAllLayers from "./layers/DeactivateAllLayers";
import RemoveLayer from "./layers/RemoveLayer";
import GetLayers from "./layers/GetLayers";
import IsLayerActive from "./layers/IsLayerActive";
import LoadLegends from "./layers/LoadLegends";
import GetStatistics from "./statistic/GetStatistics";
import LoadStatistics from "./statistic/LoadStatistics";
import { MapBrowserEvent } from "ol";
import { ToggleSelectionTool } from "./interactions/ToggleTools";

export default class Map {
  private olMap: OLMap;
  private olView: View;
  private olDrawSource: VectorSource<any>;
  private olDrawLayer: VectorLayer<any>;
  private state: State;
  public emitter: Emittery;

  /**
   * Set up the general state for this map and a resize observers for the map
   * itself so that we can resize it if the div size itself changes
   */
  public constructor() {
    this.emitter = new Emittery();

    this.olView = new View({
      center: [0, 0],
      zoom: 2,
      projection: "EPSG:3857",
    });

    this.olDrawSource = new VectorSource({ wrapX: false });
    this.olDrawLayer = new VectorLayer({
      source: this.olDrawSource,
      zIndex: 30000000,
    });

    // Mouse position is used to display the coordinates of the mouse position
    // on the map
    const mousePositionContol = new MousePosition({
      coordinateFormat: createStringXY(7),
      projection: "EPSG:4326",
      className: "custom-mouse-position",
      target: document.getElementById("mouse-position") as HTMLElement,
      undefinedHTML: "",
    });

    this.olMap = new OLMap({
      target: "map",
      pixelRatio: 1, // to request same tile sizes
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.olDrawLayer,
      ],
      view: this.olView,
      controls: [mousePositionContol],
    });

    this.state = produce(defaultState, () => {});

    // Set up a resize observer
    // TODO: apparently this isn't garbage collected when the class is no longer
    // referenced so we should manually remove it at some point
    const mapDiv = document.getElementById("map");
    if (mapDiv !== null) {
      const ro = new ResizeObserver(() => {
        this.olMap.updateSize();
      });
      ro.observe(mapDiv);
    }

    this.olDrawSource.on("addfeature", (e) => {
      if (
        this.state.interactions.boxSelection !== undefined ||
        this.state.interactions.polygonSelection !== undefined
      ) {
        this.emitter.emit(Events["statistics:drawn_loading"]);
        this.handleDrawnSelection(e);
      }
    });

    this.olMap.on("click", (e) => {
      if (
        this.state.interactions.boxSelection === undefined &&
        this.state.interactions.polygonSelection === undefined &&
        this.state.interactions.measurement.draw === undefined
      ) {
        this.olDrawSource.clear();
        this.emitter.emit(Events["statistics:click_loading"]);
        this.handleClickSelection(e);
      }
    });

    this.listenForEvents();
  }

  /**
   * Handle storing the statistics and firing off the expected events when the user draws a selection (such as the box selection tool)
   *
   * @param event The drawing event from OpenLayers
   */
  private async handleDrawnSelection(
    event: VectorSourceEvent<Geometry>
  ): Promise<void> {
    let data: { [layerName: string]: Number | string } = {};

    try {
      data = await QueryFeatureSelection(event, this.state);
    } catch (err) {
      if (err instanceof Error) {
        data["error"] = err.message;
      } else {
        data["error"] = "Unable to load statistics";
      }
    }

    let areaHa: string | undefined = undefined;

    if (event.feature !== undefined) {
      const geom = event.feature.getGeometry();

      if (geom !== undefined) {
        areaHa = String(Number(getArea(geom).toFixed(2)) / 10000);
      }
    }

    this.state = produce(this.state, (draftState) => {
      draftState.statistics.drawnCache = {};
      draftState.statistics.clickCache = undefined;

      if (areaHa !== undefined) {
        draftState.statistics.drawnCache["area_ha"] = areaHa;
      }

      for (const [layerName, stats] of Object.entries(data)) {
        draftState.statistics.drawnCache[layerName] = String(stats);
      }

      return draftState;
    });

    this.emitter.emit(
      Events["statistics:drawn_loaded"],
      this.state.statistics.drawnCache
    );
  }

  /**
   * Function for handling when the user clicks on the map for statistics
   *
   * @param event The event coming in from OpenLayers
   */
  private async handleClickSelection(
    event: MapBrowserEvent<UIEvent>
  ): Promise<void> {
    const data = await QueryClickSelection(event, this.state);

    if (data.result === "success") {
      for (const feature of data.features) {
        this.olDrawSource.addFeature(feature);
      }
    }

    this.state = produce(this.state, (draftState) => {
      draftState.statistics.drawnCache = undefined;
      draftState.statistics.clickCache = {};

      if (data.result === "success") {
        for (const [layerName, layerValues] of Object.entries(data.data)) {
          const stats: { [name: string]: any } = {};
          for (const [name, value] of Object.entries(layerValues)) {
            stats[name] = value;
          }
          draftState.statistics.clickCache[layerName] = stats;
        }
      }

      return draftState;
    });

    this.emitter.emit(
      Events["statistics:click_loaded"],
      this.state.statistics.clickCache
    );
  }

  /**
   * Handle the different events which can get fired
   */
  private listenForEvents(): void {
    this.emitter.on(Events["interaction:toggle_box"], () => {
      this.state = ToggleSelectionTool(
        this.state,
        this.olDrawSource,
        this.olMap,
        "box"
      );
    });

    this.emitter.on(Events["interaction:toggle_polygon"], () => {
      this.state = ToggleSelectionTool(
        this.state,
        this.olDrawSource,
        this.olMap,
        "polygon"
      );
    });

    this.emitter.on(Events["interaction:toggle_measurement"], () => {
      this.state = ToggleSelectionTool(
        this.state,
        this.olDrawSource,
        this.olMap,
        "measurement"
      );
    });

    this.emitter.on(Events["layers:changed"], () => {
      this.olDrawSource.clear();

      this.state = produce(this.state, (draftState) => {
        draftState.statistics.drawnCache = undefined;
        draftState.statistics.clickCache = undefined;
        return draftState;
      });
    });

    this.emitter.on(Events["timeseries:changed"], () => {
      this.olDrawSource.clear();

      this.state = produce(this.state, (draftState) => {
        draftState.statistics.drawnCache = undefined;
        draftState.statistics.clickCache = undefined;
        return draftState;
      });
    });
  }

  /**
   * Adds geoserver data to the state
   *
   * @param geoservers The geoservers to add
   */
  public addGeoservers(geoservers: Array<Geoserver>): void {
    this.state = AddGeoservers(this.state, geoservers);
    this.state = AddLayer(this.state, "imagery", this.olMap);
    CenterOnGeoservers(this.state, this.olView);

    (async () => {
      const statistics = await LoadStatistics(this.state);

      this.state = produce(this.state, (draftState) => {
        draftState.statistics.loading = false;
        draftState.statistics.byTimeGeoserverIdAndName = statistics;

        return draftState;
      });

      this.emitter.emit(Events["statistics:global_loaded"]);

      const legends = await LoadLegends(this.state);

      this.state = produce(this.state, (draftState) => {
        forEach(legends, (legend, layerId) => {
          draftState.layers.byId[layerId].legend = legend;
        });

        return draftState;
      });

      this.emitter.emit(Events["legends:loaded"]);
    })();
  }

  /**
   * Returns a list of layer names for the current active time
   *
   * @returns A array of layer names
   */
  public getLayers(): Array<LayerData> {
    return GetLayers(this.state);
  }

  /**
   * Returns the statistics data stored in the state
   *
   * @returns The stored statistics data
   */
  public getStatistics(): { data: { [name: string]: any }; loading: boolean } {
    return GetStatistics(this.state);
  }

  /**
   * Returns cached statistics for a click event
   *
   * @returns The cached statistics for a click event
   */
  public getClickStatistics():
    | undefined
    | { [layerName: string]: { [name: string]: any } } {
    return this.state.statistics.clickCache;
  }

  /**
   * Returns cached statistics for a draw event
   *
   * @returns The cached statistics for a draw event
   */
  public getDrawnStatistics(): undefined | { [name: string]: any } {
    return this.state.statistics.drawnCache;
  }

  /**
   * Returns a list of the all the available time series
   *
   * @returns A list of time series string
   */
  public getAllTimes(): Array<string> {
    return ["most_recent", ...this.state.times.allTimes];
  }

  /**
   * Toggles a layer on or off
   *
   * @param layerName The name of the layer to toggle
   */
  public toggleLayer(layerName: string): void {
    if (!IsLayerActive(this.state, layerName)) {
      this.state = AddLayer(this.state, layerName, this.olMap);
    } else {
      this.state = RemoveLayer(this.state, this.olMap, layerName);
    }

    this.emitter.emit(Events["layers:changed"]);
  }

  /**
   * Changes the active time series to a new time series
   *
   * @param newTime The time series to change to
   */
  public changeTime(newTime: string) {
    if (newTime !== this.state.times.active) {
      DeactivateAllLayers(this.state, this.olMap);
      ActivateAllLayers(this.state, this.olMap, newTime);

      this.state = produce(this.state, (draftState) => {
        draftState.times.active = newTime;
      });

      this.emitter.emit(Events["timeseries:changed"]);
    }
  }

  /**
   * Function for zooming in
   */
  public zoomIn(): void {
    const currentZoom = this.olView.getZoom();

    if (currentZoom !== undefined) {
      this.olView.animate({ zoom: currentZoom + 1, duration: 250 });
    }
  }

  /**
   * Function for zooming out
   */
  public zoomOut(): void {
    const currentZoom = this.olView.getZoom();

    if (currentZoom !== undefined) {
      this.olView.animate({ zoom: currentZoom - 1, duration: 250 });
    }
  }
}
