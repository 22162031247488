import { State } from "../interfaces";

export type GeoserverWorkspaces = { [geoserverId: string]: string };

/**
 * Returns the names of the workspaces for the geoserver ids
 *
 * @param state The state of the map
 * @returns A object of geoserverIds with the associated workspace name
 */
export function getGeoserverWorkspaces(
  state: Readonly<State>
): GeoserverWorkspaces {
  let workspaces: GeoserverWorkspaces = {};

  for (const geoserverId of state.geoservers.allIds) {
    workspaces[geoserverId] = state.geoservers.byId[geoserverId].namespace;
  }

  return workspaces;
}
