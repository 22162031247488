import produce from "immer";
import OLMap from "ol/Map";
import { State } from "../interfaces";
import { getLayerIds } from "./LayerUtils";

/**
 * Adds a layer to the map and list of active layers
 *
 * @param state The state of the map
 * @param layerName The name of the layer to activate
 * @param olMap the OpenLayers map instance
 * @returns The state with the newly added data
 */
export default function AddLayer(
  state: Readonly<State>,
  layerName: string,
  olMap: OLMap
): State {
  if (state.layers.active.indexOf(layerName) !== -1) {
    return state;
  }

  const nextState = produce(state, (draftState) => {
    if (state.times.active !== undefined) {
      const layerIds = getLayerIds(state, layerName, state.times.active);

      for (const id of layerIds) {
        const layer = state.layers.byId[id];
        if (layer.layer !== undefined) {
          olMap.addLayer(layer.layer);
        }
      }
    }

    draftState.layers.active.push(layerName);
  });

  return nextState;
}
