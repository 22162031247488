import { LoadingLayout } from "@opusinsights/ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processLogin, selectIsAuthenticated } from "../../slices/authSlice";
import getCognitoToken from "../../utils/getCognitoToken";

/**
 * Props the component needs, the children is the app or the login screen depending on the auth status of the user
 */
interface StartProps {
  children: JSX.Element;
}

/**
 * The start component which shows the user a loading screen or the app depending on what is relevant for the user
 *
 * @param props The props which the component expects
 */
function Start(props: StartProps) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [ready, setReady] = useState(false);

  /**
   * Test to see if the user has a valid login or not
   */
  useEffect(() => {
    const testLogin = async () => {
      const token = await getCognitoToken();

      if (token !== false) {
        dispatch(processLogin());
      } else {
        setReady(true);
      }
    };

    testLogin();
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !ready) {
      setReady(true);
    }
  }, [isAuthenticated, ready]);

  if (ready) {
    return props.children;
  }

  /**
   * Show the loading screen while the data is being loaded
   */
  return <LoadingLayout />;
}

export default Start;
