import { Card } from "@opusinsights/ui";
import { upperFirst } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

type StatisticsShape = {
  [layerName: string]: { [name: string]: React.ReactNode };
};

/**
 * The props expected by the layer statistics component
 */
interface LayerClickStatisticsProps {
  statistics: StatisticsShape;
  loading: boolean;
}

/**
 * The layer statistics component showing a chart with the statistics for the selected layers after a click
 *
 * @param props The props expected by the component
 */
function LayerClickStatistics(props: LayerClickStatisticsProps) {
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <Card
        title={t(`statistics.Layer Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.Loading the statistics`)}</p>
      </Card>
    );
  }

  if (Object.keys(props.statistics).length === 0) {
    return (
      <Card
        title={t(`statistics.Layer Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.There are no statistics to display`)}</p>
      </Card>
    );
  }

  return (
    <React.Fragment>
      {Object.entries(props.statistics).map(
        ([layerName, layerValue], layerIndex) => (
          <Card
            title={`${upperFirst(layerName)} ${t(
              "statistics.Layer Statistics"
            )}`}
            marginTop="medium"
            canBeMinimized={true}
            key={layerIndex}
          >
            {Object.entries(layerValue).map(([name, value], index) => (
              <p key={index}>
                <strong>
                  {i18n.exists(`statistic_values.${name}`)
                    ? t(`statistic_values.${name}`)
                    : name}
                </strong>
                : {value}
              </p>
            ))}
          </Card>
        )
      )}
    </React.Fragment>
  );
}

export default LayerClickStatistics;
