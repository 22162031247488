import { AreaNav, Sidebar, TwoColumnLayout } from "@opusinsights/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SingleAreaMap from "../../map/SingleAreaMap";
import { Area } from "../../slices/areaSlice";
import GlobalStatistics from "./Sidebar/GlobalStatistics";
import LayerSelection from "./Sidebar/LayerSelection";
import LayerStatistics from "./Sidebar/LayerStatistics";
import TimeSelection from "./Sidebar/TimeSelection";

/**
 * The props for the AreaSidebar component
 */
interface AreaSidebarProps {
  // The map class
  map: SingleAreaMap;
  // The area data from Redux
  area: Area;
}

// The tabs which can be selected in the sidebar
type possibleTabs = "statistics" | "layers" | undefined;

/**
 * The sidebar component for the area
 *
 * @param props The props which the sidebar expects
 */
function AreaSidebar(props: AreaSidebarProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<possibleTabs>("statistics");

  /**
   * Handles which sidebar component to show
   *
   * @param icon The icons which can be clicked
   */
  const handleIconClick = (icon: possibleTabs) => {
    if (activeTab === icon) {
      setActiveTab(undefined);
    } else {
      setActiveTab(icon);
    }
  };

  const iconNames = {
    statistics: t("area.Statistics"),
    layers: t("area.Layers"),
  };

  if (activeTab === undefined) {
    return (
      <AreaNav
        border={true}
        iconNames={iconNames}
        activeIcon={activeTab}
        onClick={(icon) => handleIconClick(icon)}
      />
    );
  }

  return (
    <TwoColumnLayout
      leftColumn={
        <Sidebar>
          {activeTab === "statistics" && (
            <React.Fragment>
              <TimeSelection map={props.map} />
              <GlobalStatistics map={props.map} area={props.area} />
              <LayerStatistics map={props.map} />
            </React.Fragment>
          )}
          {activeTab === "layers" && (
            <React.Fragment>
              <TimeSelection map={props.map} />
              <LayerSelection map={props.map} />
            </React.Fragment>
          )}
        </Sidebar>
      }
      rightColumn={
        <AreaNav
          border={false}
          iconNames={iconNames}
          activeIcon={activeTab}
          onClick={(icon) => handleIconClick(icon)}
        />
      }
    />
  );
}

export default AreaSidebar;
