import {
  MainWithSidebarLayout,
  MapControlsContainer,
  MapZoomButtons,
} from "@opusinsights/ui";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OverviewMapClass from "../../map/OverviewMap";
import {
  selectActiveArea,
  selectAllAreas,
  selectAreaById,
  setActiveArea,
} from "../../slices/areaSlice";
import { selectAllGeoservers } from "../../slices/geoserverSlice";
import store from "../../store";
import OverviewSidebar from "./Sidebar";

/**
 * The component for showing all the areas which a user can access
 */
function OverviewMap() {
  // Selects for the data used on the map
  const areas = useSelector(selectAllAreas);
  const activeArea = useSelector(selectActiveArea);
  const geoservers = useSelector(selectAllGeoservers);

  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const map = useRef<OverviewMapClass | null>(null);

  // Hooks for general state management
  const [haveAreasBeenAdded, setHaveAreasBeenAdded] = useState(false);
  const [haveGeoserversBeenAdded, setHaveGeoserversBeenAdded] = useState(false);

  // Create a instance of the map
  useLayoutEffect(() => {
    if (map.current === null) {
      map.current = new OverviewMapClass();

      map.current.emitter.on("map:popoverClick", (data) => {
        const selectedArea = selectAreaById(store.getState(), data.areaId);

        if (selectedArea !== null) {
          dispatch(setActiveArea(selectedArea));
          navigate(
            `/area/${selectedArea.companySlug}/${selectedArea.nameSlug}`
          );
        }
      });
    }
  }, [dispatch, navigate]);

  // Add geoserver-related data to the map
  useEffect(() => {
    if (!haveAreasBeenAdded && map.current !== null) {
      if (areas.length > 0) {
        map.current.addAreas(areas);
      }
      setHaveAreasBeenAdded(true);
    }

    if (!haveGeoserversBeenAdded && map.current !== null) {
      if (geoservers.length > 0) {
        map.current.addGeoservers(geoservers);
      } else {
        toast.error(t(`map.noAreas`), {
          duration: 4000,
        });
      }
      setHaveGeoserversBeenAdded(true);
    }
  }, [areas, geoservers, haveAreasBeenAdded, haveGeoserversBeenAdded, t]);

  return (
    <MainWithSidebarLayout
      mainContent={
        <div id="map">
          <div id="tooltipAnchor" className="tippy-anchor" />

          <MapControlsContainer>
            <MapZoomButtons
              onZoomInClick={() => {
                if (map.current !== null) {
                  map.current.zoomIn();
                }
              }}
              onZoomOutClick={() => {
                if (map.current !== null) {
                  map.current.zoomOut();
                }
              }}
            />
          </MapControlsContainer>
        </div>
      }
      sidebarContent={
        areas.length > 0 ? (
          <OverviewSidebar areas={areas} activeArea={activeArea} />
        ) : (
          ""
        )
      }
    />
  );
}

export default OverviewMap;
