import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

/**
 * The state of how all the slds are stored
 */
interface SldState {
  byName: {
    [name: string]: string;
  };
  allNames: Array<string>;
}

/**
 * How the state should be before data is added
 */
const initialState: SldState = {
  byName: {},
  allNames: [],
};

/**
 * The slice containing the reducers
 */
export const sldSlice = createSlice({
  name: "sld",
  initialState: initialState,
  reducers: {
    /**
     * Reducer for adding a SLD, checks if it has already been added before adding
     */
    addSLD: (state, action: PayloadAction<{ name: string; sld: string }>) => {
      if (state.allNames.indexOf(action.payload.name) === -1) {
        state.allNames.push(action.payload.name);
        state.byName[action.payload.name] = action.payload.sld;
      }
    },
    /**
     * Reducer for resetting the state
     */
    resetSLDs: () => {
      return initialState;
    },
  },
});

/**
 * Actions for the above defined reducers
 */
export const { addSLD, resetSLDs } = sldSlice.actions;

/**
 * Selector for getting all SLDs sorted by name
 */
export const selectAllSLDsWithNames = (state: RootState) => {
  return state.sld.byName;
};

/**
 * Selector for getting a specific SLD
 */
export const selectSLDByName = (state: RootState, sldName: string) => {
  if (state.sld.allNames.indexOf(sldName) !== -1) {
    return state.sld.byName[sldName];
  }

  return undefined;
};

export default sldSlice.reducer;
