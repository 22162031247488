import bbox from "@turf/bbox";
import { multiPoint } from "@turf/helpers";
import { View } from "ol";
import { State } from "../interfaces";

/**
 * Centers the view of the Open Layers map on the bbox of the geoservers
 *
 * @param state The state of the map
 * @param olView The Open Layers view
 */
export default function CenterOnGeoservers(
  state: Readonly<State>,
  olView: View
): void {
  let points: Array<[number, number]> = [];

  for (const [, geoserver] of Object.entries(state.geoservers.byId)) {
    points.push([geoserver.bbox[0], geoserver.bbox[1]]);
    points.push([geoserver.bbox[2], geoserver.bbox[3]]);
  }
  const areaBbox = bbox(multiPoint(points));

  olView.fit(areaBbox as [number, number, number, number]);
}
