import axios, { AxiosError } from "axios";
import GeoJSON from "ol/format/GeoJSON";
import Geometry from "ol/geom/Geometry";
import { VectorSourceEvent } from "ol/source/Vector";
import { GEO_TOOLBOX_URL } from "../../../config/aws";
import getCognitoToken from "../../../utils/getCognitoToken";
import { State } from "../interfaces";
import {
  GeoserverWorkspaces,
  getGeoserverWorkspaces,
} from "../geoservers/GeoserverUtils";
import {
  Feature as GeoFeature,
  Geometry as GeoGeometry,
  GeoJsonProperties,
} from "geojson";

/**
 * Function for getting the statistics of the area selected by the user
 *
 * @param event The selection event from the user
 * @param state The state of the map
 */
export default async function QueryFeatureSelection(
  event: VectorSourceEvent<Geometry>,
  state: Readonly<State>
): Promise<{ [layerName: string]: number }> {
  if (event === undefined || event.feature === undefined) {
    throw new Error("Unable to load the statistics");
  }

  if (state.times.active === undefined) {
    throw new Error("Could not find the active time");
  }

  if (state.layers.active.length === 0) {
    throw new Error("Could not find the active layers");
  }

  const workspaces = getGeoserverWorkspaces(state);
  const geojson = createGeojson(event, state, workspaces);

  if (geojson === undefined) {
    throw new Error("Unable to create the statistics data");
  }

  let statistics;

  try {
    statistics = await getStatistics(geojson);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response !== undefined) {
      const error = err as AxiosError;
      const data = error.response?.data as { message?: string };

      if (data.message !== undefined) {
        throw new Error(String(data.message));
      }
    }

    throw new Error("Unable to load statistics");
  }

  const statisticsPerLayer: { [layerName: string]: number } = {};

  type LayerStats = { count: string };

  for (const property of statistics.properties) {
    for (const layer of property.statistics) {
      const layerData = Object.entries(layer)[0];
      const layerName: string = layerData[0];
      const layerStats = layerData[1] as LayerStats;

      if (layerStats.count !== "NA") {
        if (statisticsPerLayer[layerName] !== undefined) {
          statisticsPerLayer[layerName] =
            statisticsPerLayer[layerName] + Number(layerStats.count);
        } else {
          statisticsPerLayer[layerName] = Number(layerStats.count);
        }
      }
    }
  }

  return statisticsPerLayer;
}

/**
 * Creates a GeoJSON with all the workspaces we know about
 *
 * @param event The event generated by the users click
 * @param state The state of the map
 * @param workspaces A list of workspaces we know about
 * @returns A GeoJSON with the list with workspaces and layers we want statistics for
 */
function createGeojson(
  event: VectorSourceEvent<Geometry>,
  state: Readonly<State>,
  workspaces: GeoserverWorkspaces
): GeoFeature<GeoGeometry, GeoJsonProperties> | undefined {
  if (event === undefined || event.feature === undefined) {
    return undefined;
  }

  const writer = new GeoJSON();
  let geojson = writer.writeFeatureObject(event.feature, {
    dataProjection: "EPSG:4326",
    featureProjection: "EPSG:3857",
  });

  const properties: Array<{
    workspace: string;
    layerNames: Array<string>;
    date: string;
  }> = [];

  for (const [geoserverId, workspace] of Object.entries(workspaces)) {
    let workspaceDate =
      state.times.active === "most_recent"
        ? state.times.mostRecentForGeoserverIdById[geoserverId]
        : state.times.active;

    if (workspaceDate !== undefined) {
      properties.push({
        workspace: workspace,
        layerNames: state.layers.active,
        date: workspaceDate,
      });
    }
  }

  geojson.properties = properties;

  return geojson;
}

/**
 * Function for getting the statistics we want from the api
 *
 * @param geojson The geojson object containing the area and workspaces we want stats from
 * @returns The statistics we requested or a error message
 */
async function getStatistics(
  geojson: GeoFeature<GeoGeometry, GeoJsonProperties>
): Promise<any> {
  const token: unknown = await getCognitoToken();

  const response = await axios({
    method: "post",
    url: `${GEO_TOOLBOX_URL}/queryfeature`,
    data: geojson,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data.result !== "success") {
    throw new Error("Unable to load the statistics");
  }

  return response.data.data;
}
