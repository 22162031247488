import produce from "immer";
import { Map } from "ol";
import VectorSource from "ol/source/Vector";
import { State } from "../interfaces";
import AddBoxSelectionInteraction from "./AddBoxSelectionInteraction";
import AddMeasurementInteraction from "./AddMeasurementInteraction";
import AddPolygonSelectionInteraction from "./AddPolygonSelectionInteraction";
import ClearInteractions from "./ClearInteractions";

/**
 * Toggles the box, polygon or measurement selection tool on or off
 *
 * @param state The state of the map
 * @param olDrawSource The OpenLayers vector source instance
 * @param olMap The OpenLayers map instance
 * @param tool The tool to toggle on or off
 * @returns The state with the box selection tool toggled
 */
export function ToggleSelectionTool(
  state: Readonly<State>,
  olDrawSource: VectorSource<any>,
  olMap: Map,
  tool: "box" | "polygon" | "measurement"
): State {
  let nextState = ClearInteractions(state, olDrawSource, olMap);
  nextState = produce(nextState, (draftState) => {
    draftState.statistics.drawnCache = undefined;

    return draftState;
  });

  if (tool === "box" && state.interactions.boxSelection === undefined) {
    nextState = AddBoxSelectionInteraction(nextState, olDrawSource, olMap);
  }

  if (tool === "polygon" && state.interactions.polygonSelection === undefined) {
    nextState = AddPolygonSelectionInteraction(nextState, olDrawSource, olMap);
  }

  if (
    tool === "measurement" &&
    state.interactions.measurement.draw === undefined
  ) {
    nextState = AddMeasurementInteraction(nextState, olDrawSource, olMap);
  }

  return nextState;
}
