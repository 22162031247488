import { yupResolver } from "@hookform/resolvers/yup";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Alert, Button, TextField } from "@opusinsights/ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { poolData } from "../../config/aws";
import i18n from "../../i18n";
import { processLogin } from "../../slices/authSlice";

/**
 * The props the component expects
 */
interface NewPasswordFormProps {
  authData: { email: string; password: string };
  setLoginSucceeded: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Types and the ypu validation for the form
 */
interface NewPasswordData {
  newPassword: string;
}

const schema = yup.object().shape({
  newPassword: yup.string().required(),
});

/**
 * The user pool instance for the Cognito validation
 */
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

/** The component for setting a new password if needed
 *
 * @param props The props which the component expects
 */
function NewPasswordForm(props: NewPasswordFormProps) {
  /**
   * General hooks
   */
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /**
   * Hooks related to the form state
   */
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordData>({
    resolver: yupResolver(schema),
  });

  /**
   * The function for handling the password reset form
   */
  const onSubmit = handleSubmit(({ newPassword }) => {
    setSubmitted(true);

    const authenticationData = {
      Username: props.authData.email,
      Password: props.authData.password,
    };

    const userData = {
      Username: props.authData.email,
      Pool: userPool,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        props.setLoginSucceeded(true);
        dispatch(processLogin());
      },
      newPasswordRequired: () => {
        if (newPassword !== null) {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: () => {
                props.setLoginSucceeded(true);
                dispatch(processLogin());
              },
              onFailure: (err) => {
                setSubmitted(false);
                setFormError(err.message);
              },
            }
          );
        }
      },
      onFailure: (err) => {
        setSubmitted(false);
        setFormError(err.message);
      },
    });
  });

  /**
   * Set up the error messages for cleaner displaying of them
   */
  let newPasswordError = undefined;
  if (errors.newPassword !== undefined) {
    if (i18n.exists(`errors.${errors.newPassword.message}`)) {
      newPasswordError = t(`errors.${errors.newPassword.message}`);
    } else {
      newPasswordError = errors.newPassword.message;
    }
  }

  /**
   * The components content
   */
  return (
    <form onSubmit={onSubmit}>
      <TextField
        id="newpassword-password"
        label={t("login.New Password")}
        register={register("newPassword")}
        password
      />

      {newPasswordError !== undefined && (
        <Alert type="error">{newPasswordError}</Alert>
      )}

      {formError !== "" && <Alert type="error">{formError}</Alert>}

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={submitted}
      >
        {t("login.Update Password")}
      </Button>
    </form>
  );
}

export default NewPasswordForm;
