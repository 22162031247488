import { point } from "@turf/helpers";
import { toMercator } from "@turf/projection";
import axios, { AxiosResponse } from "axios";
import { find } from "lodash";
import WMSCapabilities from "ol/format/WMSCapabilities";
import { put } from "redux-saga/effects";
import { HIDDEN_LAYERS } from "../layerConstants";
import {
  addGeoserver,
  Geoserver,
  GeoserverLayers,
} from "../slices/geoserverSlice";
import { APICapabilities, APIGeoserver } from "./areas";

/**
 * Function for adding a geoservers instance for a specific area to the state
 *
 * @param geoserver The Geoserver data from the api
 * @param areaId The id of the area the geoserver belongs to
 * @param capabilities The capabilities from the aggregation api
 */
export default function* handleGeoserver(
  geoserver: APIGeoserver,
  areaId: number,
  capabilities: APICapabilities | null
) {
  let geoserverData = "";

  // If the getCapabilities api worked we use the data from that, otherwise query the Geoserver directly
  if (capabilities !== null) {
    geoserverData = capabilities[geoserver.server.id][geoserver.namespace];
  } else {
    const response: AxiosResponse<any> = yield axios.get(
      `${geoserver.server.server}${geoserver.namespace}/wms`,
      {
        auth: {
          username: geoserver.username,
          password: geoserver.password,
        },
        params: {
          request: "GetCapabilities",
          namespace: geoserver.namespace,
        },
      }
    );

    geoserverData = response.data;
  }

  // See if we can parse the capabilities for this geoserver instance
  const parser = new WMSCapabilities();
  const parsed = parser.read(geoserverData);

  if (parsed === null) {
    throw new Error(
      `Unable to retrieve the layers for ${geoserver.namespace} on ${geoserver.server.server}`
    );
  }

  // Get the bounding box and the layers we have access to
  const bbox = parseBbox(parsed);
  const layers: GeoserverLayers | null = yield parseLayers(parsed);

  if (bbox === null || layers === null) {
    throw new Error(
      `Unable to retrieve the layers for ${geoserver.namespace} on ${geoserver.server.server}`
    );
  }

  const storeGeoserver: Geoserver = {
    id: Number(geoserver.id),
    areaId: areaId,
    server: geoserver.server.server,
    namespace: geoserver.namespace,
    username: geoserver.username,
    password: geoserver.password,
    bbox: bbox,
    layers: layers,
  };

  yield put(addGeoserver(storeGeoserver));
}

/**
 * Function for getting the bounding box out of the capabilities
 *
 * @param capabilities The parsed capabilities from the api
 */
export const parseBbox = (capabilities: any) => {
  let points: Array<[number, number]> = [];
  const layersBbox = capabilities["Capability"]["Layer"]["BoundingBox"];

  // See if we can find a bbox with our preferred EPSG for this layer and add its points
  let foundBbox = find(layersBbox, { crs: "EPSG:4326" });

  if (foundBbox !== undefined) {
    points.push([foundBbox.extent[1], foundBbox.extent[0]]);
    points.push([foundBbox.extent[3], foundBbox.extent[2]]);
  } else {
    // Otherwise try to do the same for CRS 84 (same projection but the coords are stored the other way around)
    foundBbox = find(layersBbox, { crs: "CRS:84" });

    if (foundBbox !== undefined) {
      points.push([foundBbox.extent[0], foundBbox.extent[1]]);
      points.push([foundBbox.extent[2], foundBbox.extent[3]]);
    }
  }

  // If we could not find a bbox we will bail
  if (points.length === 0) {
    return null;
  }

  // Convert the bbox points to mercator
  const min = toMercator(point(points[0]));
  const max = toMercator(point(points[1]));

  // As before, no bbox means we're bailing out
  if (min.geometry === null || max.geometry === null) {
    return null;
  }

  return [
    min.geometry.coordinates[0],
    min.geometry.coordinates[1],
    max.geometry.coordinates[0],
    max.geometry.coordinates[1],
  ] as [number, number, number, number];
};

/**
 * Get all the accessible layers from the capabilities
 *
 * @param capabilities The parsed capabilities from the api
 */
export const parseLayers = async (capabilities: any) => {
  let layers: GeoserverLayers = { byTime: {}, allTimes: [] };
  let totalLayers = 0;

  for (const layer of capabilities["Capability"]["Layer"]["Layer"]) {
    const time = find(layer.Dimension, {
      name: "time",
    });

    // The layer needs to have a time and shouldn't be one of the layers we hide
    if (time !== undefined && HIDDEN_LAYERS.indexOf(layer.Name) === -1) {
      const times = time.values.split(",");

      for (const time of times) {
        // Add a empty time array if needed for this time
        if (layers.byTime[time] === undefined) {
          layers.byTime[time] = [];
        }

        // Add the time to the index of times if needed
        if (layers.allTimes.indexOf(time) === -1) {
          layers.allTimes.push(time);
        }

        let styles: Array<string> = [];

        for (const [, style] of Object.entries(layer.Style)) {
          styles.push((style as any).Name);
        }

        // Finally add the layer data and increment the total layers counter
        layers.byTime[time].push({
          name: layer.Name,
          keywords: layer.KeywordList,
          styles: styles,
        });
        totalLayers += 1;
      }
    }
  }

  // If there aren't any layers then we'll let them calling function know with a null
  if (totalLayers === 0) {
    return null;
  }

  return layers;
};
