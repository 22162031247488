import { State } from "../interfaces";

/**
 * Get the ids for the layers associated with the supplied name and time series
 * This function extends getIdsForTimeSeriesAndName to handle the most_recent time
 *
 * @param state The state of the map
 * @param layerName The name of the layer to find the ids for
 * @param time The time for which to get the ids
 * @returns A array with layer ids
 */
export function getLayerIds(
  state: Readonly<State>,
  layerName: string,
  time: string
): Array<string> {
  // Handle the special most recent case
  if (time === "most_recent") {
    let ids: Array<string> = [];

    //  Go through all the recent times and get the list of ids spanning multiple time series to show
    for (const [mostRecentTime, geoserverIds] of Object.entries(
      state.times.mostRecentForGeoserverIdByTime
    )) {
      for (const geoserverId of geoserverIds) {
        ids = [
          ...getIdsForTimeSeriesGeoserverIdAndName(
            state,
            geoserverId,
            layerName,
            mostRecentTime
          ),
          ...ids,
        ];
      }
    }

    return ids;
  }

  return getIdsForTimeSeriesAndName(state, layerName, time);
}

/**
 * Gets the ids for a specific time series and layer name
 *
 * @param state The state of the map
 * @param layerName The name of the layer to find the ids for
 * @param time The time for which to get the ids
 * @returns A array with layer ids
 */
function getIdsForTimeSeriesAndName(
  state: Readonly<State>,
  layerName: string,
  time: string
): Array<string> {
  if (
    state.layers.idsByTimeSeriesAndName[time] !== undefined &&
    state.layers.idsByTimeSeriesAndName[time][layerName] !== undefined
  ) {
    return state.layers.idsByTimeSeriesAndName[time][layerName];
  }

  return [];
}

/**
 * Gets the ids for a specific time series and geoserver id
 *
 * @param state The state of the map
 * @param geoserverId The geoserver id for which to find the layers
 * @param layerName The name of the layer to find the ids for
 * @param time The time for which to get the ids
 * @returns A array with layer ids
 */
function getIdsForTimeSeriesGeoserverIdAndName(
  state: Readonly<State>,
  geoserverId: string,
  layerName: string,
  time: string
): Array<string> {
  const ids =
    state.layers.idsByTimeSeriesGeoserverIdAndName[time]?.[geoserverId]?.[
      layerName
    ];

  if (ids === undefined) {
    return [];
  }

  return ids;
}
