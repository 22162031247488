import { Overlay } from "ol";
import Draw from "ol/interaction/Draw";
import TileLayer from "ol/layer/Tile";
import { Geoserver } from "../../slices/geoserverSlice";

export interface LayerData {
  id: string;
  name: string;
  active: boolean;
  legend: undefined | string | false;
}

export interface State {
  // The data for the geoservers we need to communicate with
  geoservers: {
    byId: { [id: string]: Geoserver };
    allIds: Array<string>;
  };
  // The different time series the user can select, we also add a "most recent" option later
  // This option will show the most recent time series from all geoservers even if they are from different times
  times: {
    allTimes: Array<string>;
    active: string | "most_recent" | undefined;
    // A list of the most recent time series and the geoserver ids for which this is the most recent
    mostRecentForGeoserverIdByTime: {
      [time: string]: Array<string>;
    };
    mostRecentForGeoserverIdById: {
      [geoserverId: string]: string;
    };
  };
  // The layers the user can see, where the ids are uuids
  layers: {
    byId: {
      [id: string]: {
        geoserverId: string;
        layer: TileLayer<any>;
        legend: undefined | string | false;
        name: string;
        time: string;
      };
    };
    allIds: Array<string>;
    // Sorting the ids in a way which makes it easier to figure out which layers can be selected by the user
    idsByTimeSeriesAndName: {
      [timeSeries: string]: {
        [name: string]: Array<string>;
      };
    };
    // Sorting the ids in a way which will make it more efficient to figure out which layers to show when the most recent option is selected
    idsByTimeSeriesGeoserverIdAndName: {
      [timeSeries: string]: {
        [id: string]: {
          [name: string]: Array<string>;
        };
      };
    };
    // The current active layers based on names rather than by id (e.g. imagery)
    active: Array<string>;
  };
  // The data for the statistics to show in the sidebar
  statistics: {
    byTimeGeoserverIdAndName: {
      [time: string]: { [geoserverId: string]: { [name: string]: string } };
    };
    loading: boolean;
    // Caches so that we do not have to refetch data if the user switches away from the statistics screen and then goes back
    clickCache: undefined | { [layerName: string]: { [name: string]: any } };
    drawnCache: undefined | { [name: string]: string };
  };
  // The different OpenLayers objects for dealing with the tools a user has for interacting with the map
  interactions: {
    boxSelection: Draw | undefined;
    polygonSelection: Draw | undefined;
    measurement: {
      draw: Draw | undefined;
      measureTooltip: Overlay | undefined;
      helpTooltip: Overlay | undefined;
    };
  };
}

export enum Events {
  "interaction:toggle_box" = "INTERACTION_TOGGLE_BOX",
  "interaction:toggle_measurement" = "INTERACTION_TOGGLE_MEASUREMENT",
  "interaction:toggle_polygon" = "INTERACTION_TOGGLE_POLYGON",
  "layers:changed" = "LAYERS_CHANGED",
  "legends:loaded" = "LEGENDS_LOADED",
  "statistics:click_loaded" = "STATISTICS_CLICK_LOADED",
  "statistics:click_loading" = "STATISTICS_CLICK_LOADING",
  "statistics:drawn_loaded" = "STATISTICS_DRAWN_LOADED",
  "statistics:drawn_loading" = "STATISTICS_DRAWN_LOADING",
  "statistics:global_loaded" = "STATISTICS_GLOBAL_LOADED",
  "timeseries:changed" = "TIMESERIES_CHANGED",
}
