import { Select } from "@opusinsights/ui";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleAreaMap from "../../../map/SingleAreaMap";

// Make sure dayjs works using utc
dayjs.extend(utc);

/**
 * The props expected by the time selection component
 */
interface TimeSelectionProps {
  map: SingleAreaMap;
}

/**
 * Component for selecting the active time series
 *
 * @param props The props which the component expects
 */
function TimeSelection(props: TimeSelectionProps) {
  const { t } = useTranslation();
  const [times, setTimes] = useState<Array<{ value: string; label: string }>>(
    []
  );

  useEffect(() => {
    const mapTimes = props.map.getAllTimes();

    const t = mapTimes.reduce((acc, curr) => {
      let label =
        curr !== "most_recent"
          ? dayjs(curr).utc().format("DD-MM-YYYY")
          : "Most Recent";

      acc.push({
        value: curr,
        label: label,
      });

      return acc;
    }, [] as Array<{ value: string; label: string }>);

    setTimes(t);
  }, [props.map]);

  return (
    <Select
      id="area-select-id"
      label={t("area.Time Series")}
      hiddenLabel={false}
      title={t("area.Time Series")}
      options={times}
      onChange={(value) => {
        props.map.changeTime(value);
      }}
    />
  );
}

export default TimeSelection;
