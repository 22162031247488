import axios from "axios";
import { State } from "../interfaces";
import * as Sentry from "@sentry/react";
import { isString } from "lodash";

/**
 * Retrieves the legends from Geoserver and stores them as base64s in the state
 *
 * @param state The state of the map
 * @returns Updated state with the base64 legends (or false if unable to load)
 */
export default async function LoadLegends(
  state: Readonly<State>
): Promise<{ [layerId: string]: string | false }> {
  const legends: { [layerId: string]: string | false } = {};

  for (const [layerId, layer] of Object.entries(state.layers.byId)) {
    try {
      const source = layer.layer.getSource();
      const geoserver = state.geoservers.byId[layer.geoserverId];

      const response = await axios.get(source.getLegendUrl(), {
        auth: {
          username: geoserver.username,
          password: geoserver.password,
        },
        responseType: "blob",
      });

      const base64 = await createBase64(response.data);
      const base64Prepend = "data:image/png;base64,";

      if (
        isString(base64) &&
        base64.substring(0, base64Prepend.length) === base64Prepend
      ) {
        legends[layerId] = base64.substring(base64Prepend.length);
      }
    } catch (err) {
      Sentry.captureException(err);

      legends[layerId] = false;
    }
  }

  return legends;
}

/**
 * Small helper function to turn the blob into base64
 *
 * @param data The image blob
 * @returns The base64 string of the image
 */
async function createBase64(data: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(data);
  });
}
