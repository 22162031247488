import produce from "immer";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { Geoserver } from "../../../slices/geoserverSlice";
import createWMSLayer from "../../Utils/createWMSLayer";
import { State } from "../interfaces";

/**
 * Adds the supplied geoserver data to the state and sets up the associated data
 * such as the imagery base layers
 *
 * @param state The state of the map
 * @param geoservers The geoserver data to add
 * @returns The state with the newly added data
 */
export default function AddGeoservers(
  state: Readonly<State>,
  geoservers: Array<Geoserver>
) {
  // Operations for building up the state
  const addedGeoservers = addGeoserversToTheState(state, geoservers);

  if (addedGeoservers.newGeoserverIds.length > 0) {
    let nextState = addedGeoservers.nextState;
    nextState = addTimeseriesToTheState(
      nextState,
      addedGeoservers.newGeoserverIds
    );
    nextState = addLayersToTheState(nextState, addedGeoservers.newGeoserverIds);
    return nextState;
  }

  return state;
}

/**
 * Adds new geoserver data to the state
 *
 * @param state The state of the map
 * @param geoservers The geoserver data to add
 * @returns The updated state and the ids of the geoservers which have been
 *     added
 */
function addGeoserversToTheState(
  state: Readonly<State>,
  geoservers: Array<Geoserver>
): { nextState: State; newGeoserverIds: Array<string> } {
  const existingGeoserverIds: Array<number> = [];
  const newGeoserverIds: Array<string> = [];

  for (const [, geoserver] of Object.entries(state.geoservers.byId)) {
    existingGeoserverIds.push(geoserver.id);
  }

  const nextState = produce(state, (draftState) => {
    for (const geoserver of geoservers) {
      if (existingGeoserverIds.indexOf(geoserver.id) === -1) {
        const geoserverId = uuidv4();
        draftState.geoservers.byId[geoserverId] = geoserver;
        draftState.geoservers.allIds.push(geoserverId);
        newGeoserverIds.push(geoserverId);
      }
    }
  });

  return { nextState: nextState, newGeoserverIds: newGeoserverIds };
}

/**
 * Adds the time series information for easy access to the state
 * including setting the active time to the newest available times
 *
 * @param state The state of the map
 * @param geoserverIds The geoservers which to use for adding timeseries
 * @returns The updated state
 */
function addTimeseriesToTheState(
  state: Readonly<State>,
  geoserverIds: Array<string>
): State {
  const nextState = produce(state, (draftState) => {
    for (const geoserverId of geoserverIds) {
      const geoserver = state.geoservers.byId[geoserverId];

      // We run it through uniq to make sure we don't get any duplicate time
      // series This can happen quite easily with multiple geoservers
      draftState.times.allTimes = uniq([
        ...geoserver.layers.allTimes,
        ...draftState.times.allTimes,
      ]);

      const sortedTimes = [...geoserver.layers.allTimes].sort().reverse();
      if (sortedTimes.length > 0) {
        // Add the time to the most recent collection based on the time index
        const mostRecent = sortedTimes[0];
        if (
          draftState.times.mostRecentForGeoserverIdByTime[mostRecent] ===
          undefined
        ) {
          draftState.times.mostRecentForGeoserverIdByTime[mostRecent] = [];
        }
        draftState.times.mostRecentForGeoserverIdByTime[mostRecent].push(
          geoserverId
        );

        // Add the time to the most recent collection based on the geoserver id
        draftState.times.mostRecentForGeoserverIdById[geoserverId] = mostRecent;
      }
    }

    draftState.times.active = "most_recent";
  });

  return nextState;
}

/**
 * Adds the known layers from Geoserver to the state
 *
 * @param state The state of the map
 * @param geoserverIds The geoservers which to use for adding layers
 * @returns The updated state
 */
function addLayersToTheState(
  state: Readonly<State>,
  geoserverIds: Array<string>
): State {
  const nextState = produce(state, (draftState) => {
    for (const geoserverId of geoserverIds) {
      const geoserver = state.geoservers.byId[geoserverId];

      for (const time of geoserver.layers.allTimes) {
        // Set up the parent objects for the time series if they do not exist
        // yet
        if (draftState.layers.idsByTimeSeriesAndName[time] === undefined) {
          draftState.layers.idsByTimeSeriesAndName[time] = {};
        }
        if (
          draftState.layers.idsByTimeSeriesGeoserverIdAndName[time] ===
          undefined
        ) {
          draftState.layers.idsByTimeSeriesGeoserverIdAndName[time] = {};
        }
        if (
          draftState.layers.idsByTimeSeriesGeoserverIdAndName[time][
            geoserverId
          ] === undefined
        ) {
          draftState.layers.idsByTimeSeriesGeoserverIdAndName[time][
            geoserverId
          ] = {};
        }

        for (const layer of geoserver.layers.byTime[time]) {
          const layerId = uuidv4();

          const tileLayer = createWMSLayer(geoserver, layer.name, time);

          // Add the layer data itself
          draftState.layers.byId[layerId] = {
            geoserverId: geoserverId,
            layer: tileLayer,
            legend: undefined,
            name: layer.name,
            time: time,
          };

          // Add the id to the index of all ids
          draftState.layers.allIds.push(layerId);

          // Link the id to the layer name under its time series
          if (
            draftState.layers.idsByTimeSeriesAndName[time][layer.name] ===
            undefined
          ) {
            draftState.layers.idsByTimeSeriesAndName[time][layer.name] = [];
          }
          draftState.layers.idsByTimeSeriesAndName[time][layer.name].push(
            layerId
          );

          // Link the id to the geoserver it belongs to under its time series
          if (
            draftState.layers.idsByTimeSeriesGeoserverIdAndName[time][
              geoserverId
            ][layer.name] === undefined
          ) {
            draftState.layers.idsByTimeSeriesGeoserverIdAndName[time][
              geoserverId
            ][layer.name] = [];
          }
          draftState.layers.idsByTimeSeriesGeoserverIdAndName[time][
            geoserverId
          ][layer.name].push(layerId);
        }
      }
    }
  });

  return nextState;
}
