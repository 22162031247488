import { Alert, Button, TextField } from "@opusinsights/ui";
import React, { useState } from "react";
import * as yup from "yup";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../../config/aws";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

/**
 * The props which the new password form expects
 */
interface NewPasswordFormProps {
  resetEmail: string;
}

// The shape of the data and the yup validation used in the form
interface NewPasswordFormData {
  code: string;
  newPassword: string;
}

const schema = yup.object().shape({
  code: yup.string().required(),
  newPassword: yup.string().required(),
});

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

/**
 * Compont for entering a new password and the reset code for the account which the user is resetting
 *
 * @param props The props which the component expects
 */
function NewPasswordForm(props: NewPasswordFormProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ code, newPassword }) => {
    setSubmitted(true);

    const userData = {
      Username: props.resetEmail,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: function () {
        setSubmitted(false);
        toast.success(t(`settings.Your password has been changed`), {
          duration: 4000,
        });
        navigate("/login");
      },
      onFailure: function (err) {
        setSubmitted(false);
        setFormError(err.message);
      },
    });
  });

  let codeError = undefined;
  if (errors.code !== undefined) {
    if (i18n.exists(`errors.${errors.code.message}`)) {
      codeError = t(`errors.${errors.code.message}`);
    } else {
      codeError = errors.code.message;
    }
  }

  let newPasswordError = undefined;
  if (errors.newPassword !== undefined) {
    if (i18n.exists(`errors.${errors.newPassword.message}`)) {
      newPasswordError = t(`errors.${errors.newPassword.message}`);
    } else {
      newPasswordError = errors.newPassword.message;
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <TextField
        id="reset-code"
        label={t("login.Enter reset code")}
        register={register("code")}
      />

      {codeError !== undefined && <Alert type="error">{codeError}</Alert>}

      <TextField
        id="reset-password"
        label={t("login.Enter your new password")}
        register={register("newPassword")}
        password
      />

      {newPasswordError !== undefined && (
        <Alert type="error">{newPasswordError}</Alert>
      )}

      {formError !== "" && <Alert type="error">{formError}</Alert>}

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={submitted}
      >
        {t("login.Change Password")}
      </Button>
    </form>
  );
}

export default NewPasswordForm;
