import produce from "immer";
import GeometryType from "ol/geom/GeometryType";
import Draw from "ol/interaction/Draw";
import OLMap from "ol/Map";
import VectorSource from "ol/source/Vector";
import { State } from "../interfaces";

/**
 * Creates a draw class which allows the user to draw a poloygon for selection
 *
 * @param state The state of the map
 * @param drawSource The source which the drawing happens on
 * @param olMap The OpenLayers map instance
 * @returns The state with the interactions added
 */
export default function AddPolygonSelectionInteraction(
  state: Readonly<State>,
  drawSource: VectorSource<any>,
  olMap: OLMap
): State {
  let draw = new Draw({
    source: drawSource,
    type: GeometryType["POLYGON"],
  });

  draw.on("drawstart", () => {
    drawSource.clear();
  });

  const nextState = produce(state, (draftState) => {
    draftState.interactions.polygonSelection = draw;
  });

  olMap.addInteraction(draw);

  return nextState;
}
