import axios, { AxiosResponse } from "axios";
import { all } from "redux-saga/effects";
import { GEOSERVER_META_URL, GRAPHQL_URL } from "../config/aws";
import getCognitoToken from "../utils/getCognitoToken";
import handleArea from "./area";
import * as Sentry from "@sentry/react";

/**
 * GraphQL query for getting all the areas which a user can access
 */
const graphqlQuery = `query {
    areas {
      id
      name
      company {
        id
        name
      }
      geoservers {
        id
        namespace
        username
        password
        server {
          id
          server
        }
      }
    }
  }
`;

export interface APIGeoserver {
  id: string;
  namespace: string;
  username: string;
  password: string;
  server: {
    id: string;
    server: string;
  };
}

/**
 * The shape of a area coming in from the graphql
 */
export interface APIArea {
  id: string;
  name: string;
  company: {
    id: string;
    name: string;
  };
  geoservers: APIGeoserver[];
}

/**
 * The shape of the capabilities coming in from the api
 */
export interface APICapabilities {
  [id: string]: {
    [area: string]: string;
  };
}

/**
 * Small helper function for getting the capabilities of all the areas a user can access
 */
async function getCapabilities(): Promise<AxiosResponse<APICapabilities> | null> {
  try {
    const token: unknown = await getCognitoToken();

    const capabilities = await axios.get(
      `${GEOSERVER_META_URL}/get_capabilities`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return capabilities.data;
  } catch (err) {
    Sentry.captureException(err);

    return null;
  }
}

/**
 * Get all the areas and then parse them through handleArea
 */
export default function* handleAreas() {
  const capabilities: APICapabilities | null = yield getCapabilities();

  try {
    const token: unknown = yield getCognitoToken();

    const response: AxiosResponse<any> = yield axios.post(
      GRAPHQL_URL,
      {
        query: graphqlQuery,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const areas: Array<Generator<any>> = [];

    for (const area of response.data.data.areas) {
      areas.push(handleArea(area as APIArea, capabilities));
    }

    yield all(areas);
    // We're currently not using the SLDs so we don't have to call this function
    // TODO: Remove?
    //yield handleSld();
  } catch (err) {
    Sentry.captureException(err);
  }
}
