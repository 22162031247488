import produce from "immer";
import { without } from "lodash";
import OLMap from "ol/Map";
import { State } from "../interfaces";
import { getLayerIds } from "./LayerUtils";

/**
 * Removes a layer from the map and the active layers list
 *
 * @param state The state of the map
 * @param olMap The OpenLayers map instance
 * @param layerName The name of the layer to deactivate
 * @returns The updated state
 */
export default function RemoveLayer(
  state: Readonly<State>,
  olMap: OLMap,
  layerName: string
): State {
  if (state.times.active === undefined) {
    return state;
  }

  if (state.layers.active.indexOf(layerName) === -1) {
    return state;
  }

  const layerIds = getLayerIds(state, layerName, state.times.active);

  const nextState = produce(state, (draftState) => {
    for (const id of layerIds) {
      olMap.removeLayer(state.layers.byId[id].layer);
    }

    draftState.layers.active = without(state.layers.active, layerName);
  });

  return nextState;
}
