import { CenteredContentLayout } from "@opusinsights/ui";
import React from "react";
import GeneralInformation from "./GeneralInformation";
import NewPassword from "./NewPassword";

/**
 * The parent component for the settings screen
 */
export default function Settings() {
  return (
    <CenteredContentLayout>
      <NewPassword />

      <GeneralInformation />
    </CenteredContentLayout>
  );
}
