import { State } from "../interfaces";

/**
 * Returns if a layer is active or not
 *
 * @param state The state of the map
 * @param layerName The layer to check
 * @returns True if the layer is active, otherwise false
 */
export default function IsLayerActive(
  state: Readonly<State>,
  layerName: string
): boolean {
  return state.layers.active.indexOf(layerName) !== -1;
}
