import React, { useEffect, useState } from "react";
import SingleAreaMap from "../../../map/SingleAreaMap";
import { Events } from "../../../map/SingleAreaMap/interfaces";
import formatItem from "../Utils/formatValue";
import LayerClickStatistics from "./LayerClickStatistics";
import LayerDrawnStatistics from "./LayerDrawnStatistics";

/**
 * The props expected by the layer statistics component
 */
interface LayerStatisticsProps {
  map: SingleAreaMap;
}

type ClickStatistics = {
  [layerName: string]: { [name: string]: React.ReactNode };
};
type DrawnStatistics = { [name: string]: React.ReactNode };

/**
 * Small helper function to format the values of the statistics generated by a click
 *
 * @param statistics The unformatted statistics
 * @returns The statistics with its values formatted
 */
function formatClickStatistics(statistics: ClickStatistics): ClickStatistics {
  const formatted: ClickStatistics = {};

  for (const [layerName, layerValues] of Object.entries(statistics)) {
    formatted[layerName] = {};

    for (const [name, value] of Object.entries(layerValues)) {
      formatted[layerName][name] = formatItem(name, value);
    }
  }

  return formatted;
}

/**
 * Small helper function to format the values of the statistics generated through drawing a shape
 *
 * @param statistics The unformatted statistics
 * @returns The statistics with its values formatted
 */
function formatDrawnStatistics(statistics: DrawnStatistics): DrawnStatistics {
  const formatted: DrawnStatistics = {};

  for (const [name, value] of Object.entries(statistics)) {
    formatted[name] = formatItem(name, value);
  }

  return formatted;
}

/**
 * The layer statistics component showing a chart with the statistics for the selected layers after a click
 *
 * @param props The props expected by the component
 */
function LayerStatistics(props: LayerStatisticsProps) {
  const [clickStatistics, setClickStatistics] =
    useState<ClickStatistics | undefined>(undefined);
  const [drawnStatistics, setDrawnStatistics] =
    useState<DrawnStatistics | undefined>(undefined);
  const [loading, setLoading] =
    useState<"clickSelection" | "drawnSelection" | false>(false);

  useEffect(() => {
    const clicked = props.map.getClickStatistics();
    if (clicked !== undefined) {
      setClickStatistics(formatClickStatistics(clicked));
    }

    const drawn = props.map.getDrawnStatistics();
    if (drawn !== undefined) {
      setDrawnStatistics(formatDrawnStatistics(drawn));
    }
  }, [props.map]);

  useEffect(() => {
    let mounted = true;

    props.map.emitter.on(Events["statistics:drawn_loading"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
        setLoading("drawnSelection");
      }
    });

    props.map.emitter.on(Events["statistics:drawn_loaded"], (statistics) => {
      if (mounted) {
        setLoading(false);
        setDrawnStatistics(formatDrawnStatistics(statistics));
      }
    });

    props.map.emitter.on(Events["statistics:click_loading"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
        setLoading("clickSelection");
      }
    });

    props.map.emitter.on(Events["statistics:click_loaded"], (statistics) => {
      if (mounted) {
        setLoading(false);
        setClickStatistics(formatClickStatistics(statistics));
      }
    });

    props.map.emitter.on(Events["interaction:toggle_box"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
      }
    });

    props.map.emitter.on(Events["interaction:toggle_polygon"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
      }
    });

    props.map.emitter.on(Events["timeseries:changed"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
      }
    });

    props.map.emitter.on(Events["layers:changed"], () => {
      if (mounted) {
        setClickStatistics(undefined);
        setDrawnStatistics(undefined);
      }
    });

    return () => {
      mounted = false;
    };
  }, [props.map]);

  if (loading === "drawnSelection") {
    return <LayerDrawnStatistics statistics={{}} loading={true} />;
  }

  if (drawnStatistics !== undefined) {
    return (
      <LayerDrawnStatistics statistics={drawnStatistics} loading={false} />
    );
  }

  if (loading === "clickSelection") {
    return <LayerClickStatistics statistics={{}} loading={true} />;
  }

  if (clickStatistics !== undefined) {
    return (
      <LayerClickStatistics statistics={clickStatistics} loading={false} />
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default LayerStatistics;
