import { Card } from "@opusinsights/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

type StatisticsShape = { [name: string]: React.ReactNode };

/**
 * The props expected by the selection statistics component
 */
interface LayersDrawnStatisticsProps {
  statistics: StatisticsShape;
  loading: boolean;
}

/**
 * Component which shows the statistics after a selection was made with the selection tool
 *
 * @param props The props which the component expects
 */
function LayerDrawnStatistics(props: LayersDrawnStatisticsProps) {
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <Card
        title={t(`statistics.Selection Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.Loading the statistics`)}</p>
      </Card>
    );
  }

  if (Object.keys(props.statistics).length === 0) {
    return (
      <Card
        title={t(`statistics.Layer Statistics`)}
        marginTop="medium"
        canBeMinimized={true}
      >
        <p>{t(`statistics.There are no statistics to display`)}</p>
      </Card>
    );
  }

  return (
    <Card
      title={t(`statistics.Selection Statistics`)}
      marginTop="medium"
      canBeMinimized={true}
    >
      {Object.entries(props.statistics).map(([name, value]) => (
        <p key={name}>
          <strong>
            {i18n.exists(`statistic_values.${name}`)
              ? t(`statistic_values.${name}`)
              : name}
          </strong>
          : {value}
        </p>
      ))}
    </Card>
  );
}

export default LayerDrawnStatistics;
