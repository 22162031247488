import { AppLayout } from "@opusinsights/ui";
import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { RootState } from "../../store";
import Area from "../Area/Area";
import Login from "../Login/Login";
import OverviewMap from "../OverviewMap/OverviewMap";
import PasswordReset from "../PasswordReset/PasswordReset";
import Settings from "../Settings/Settings";
import AppHeader from "./AppHeader";
import AppNav from "./AppNav";

/**
 * Simple wrapper function to allow routes to be auth-only
 *
 * @param children The component to show if the user is logged in
 */
function PrivateRoute({ children }: any) {
  const authenticated = useSelector(
    (state: RootState) => state.auth.authenticated
  );

  return authenticated ? children : <Navigate to="/login" />;
}

/**
 * The wrapper component for the app, holding the routing
 */
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<PasswordReset />} />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <AppLayout
                header={<AppHeader />}
                nav={<AppNav activePage="settings" />}
                main={<Settings />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/area/:companySlug/:areaSlug"
          element={
            <PrivateRoute>
              <AppLayout
                header={<AppHeader />}
                nav={<AppNav activePage="area" />}
                main={<Area />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AppLayout
                header={<AppHeader />}
                nav={<AppNav activePage="overview" />}
                main={<OverviewMap />}
              />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
