import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import areaReducer from "./slices/areaSlice";
import authReducer from "./slices/authSlice";
import geoserverReducer from "./slices/geoserverSlice";
import sldReducer from "./slices/sldSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    area: areaReducer,
    geoserver: geoserverReducer,
    sld: sldReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(sagas);

export type RootState = ReturnType<typeof store.getState>;

export default store;
