import { merge } from "lodash";
import { LayerData, State } from "../interfaces";

/**
 * Returns a list of selectable layers for the current time series
 *
 * @param state The state of the map
 * @returns A array of layer names, if they are active or not and the legend url
 */
export default function GetLayers(state: Readonly<State>): Array<LayerData> {
  // Let's make sure there is a time series to work with
  if (state.times.active === undefined) {
    return [];
  }

  let layers: { [layerName: string]: LayerData } = {};

  if (state.times.active === "most_recent") {
    const times: Array<string> = [];

    for (const [time] of Object.entries(
      state.times.mostRecentForGeoserverIdByTime
    )) {
      times.push(time);
    }
    times.sort().reverse();

    for (const time of times) {
      layers = merge(
        layers,
        GetLayersForTimeSeries(state, time, Object.keys(layers))
      );
    }
  } else {
    layers = GetLayersForTimeSeries(state, state.times.active, []);
  }

  return Object.values(layers);
}

/**
 * Returns a list of selectable layers for a specific time series
 *
 * @param state The state of the map
 * @param time The time series for which to get the layer data
 * @param namesToIgnore A array of layer names to ignore when encountered
 * @returns A array of layer names, if they are active or not and the legend url
 */
function GetLayersForTimeSeries(
  state: Readonly<State>,
  time: string,
  namesToIgnore: Array<string>
): { [layerName: string]: LayerData } {
  const layers: { [layerName: string]: LayerData } = {};

  // Make sure there is data to work with
  if (state.layers.idsByTimeSeriesAndName[time] === undefined) {
    return layers;
  }

  // Run through the data which is there
  for (const [name, ids] of Object.entries(
    state.layers.idsByTimeSeriesAndName[time]
  )) {
    if (namesToIgnore.indexOf(name) === -1) {
      const layer = {
        id: name,
        name: name,
        active: false,
        legend: state.layers.byId[ids[0]].legend,
      };
      // Set the layer as active if it's present in the list of active layers
      if (state.layers.active.indexOf(name) !== -1) {
        layer.active = true;
      }

      layers[name] = layer;
    }
  }

  return layers;
}
