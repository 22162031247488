import OLMap from "ol/Map";
import { State } from "../interfaces";
import { getLayerIds } from "./LayerUtils";

/**
 * Removes all the layers from the map for the current active time
 *
 * @param state The state of the map
 * @param olMap The OpenLayers map instance
 */
export default function DeactivateAllLayers(
  state: Readonly<State>,
  olMap: OLMap
): void {
  const activeTime = state.times.active;

  if (activeTime === undefined) {
    return;
  }

  for (const layerName of state.layers.active) {
    const layerIds = getLayerIds(state, layerName, activeTime);

    for (const layerId of layerIds) {
      const layer = state.layers.byId[layerId].layer;

      if (layer !== undefined) {
        olMap.removeLayer(layer);
      }
    }
  }

  return;
}
