import { yupResolver } from "@hookform/resolvers/yup";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Alert, Button, Buttons, TextField } from "@opusinsights/ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { poolData } from "../../config/aws";
import { processLogin } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";

/**
 * The props the component expects
 */
interface LoginFormProps {
  setAuthData: React.Dispatch<
    React.SetStateAction<{
      email: string;
      password: string;
    }>
  >;
  setLoginSucceeded: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Types and yup validation for the form
 */
interface LoginData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

/**
 * User pool instance for the Cognito validation
 */
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

/**
 * Component for the login form
 *
 * @param props The props which the login form expects
 */
function LoginForm(props: LoginFormProps) {
  /**
   * General hooks
   */
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**
   * Hooks related to the form state
   */
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  });

  /**
   * The function for handling the form submission.
   */
  const onSubmit = handleSubmit(({ email, password }) => {
    setSubmitted(true);

    const authenticationData = {
      Username: email,
      Password: password,
    };

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    // Try to log the user in, telling the parent component to show the password reset form if needed
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        props.setLoginSucceeded(true);
        dispatch(processLogin());
      },
      newPasswordRequired: () => {
        props.setAuthData({ email: email, password: password });
      },
      onFailure: (err) => {
        setSubmitted(false);
        setFormError(err.message);
      },
    });
  });

  /**
   * Set up the error messages for cleaner displaying of them
   */
  let emailError = undefined;
  if (errors.email !== undefined) {
    if (i18n.exists(`errors.${errors.email.message}`)) {
      emailError = t(`errors.${errors.email.message}`);
    } else {
      emailError = errors.email.message;
    }
  }

  let passwordError = undefined;
  if (errors.password !== undefined) {
    if (i18n.exists(`errors.${errors.password.message}`)) {
      passwordError = t(`errors.${errors.password.message}`);
    } else {
      passwordError = errors.password.message;
    }
  }

  /**
   * Return to build up the form
   */
  return (
    <form onSubmit={onSubmit}>
      <TextField
        id="login-email"
        label={t("login.Email")}
        register={register("email")}
      />

      {emailError !== undefined && <Alert type="error">{emailError}</Alert>}

      <TextField
        id="login-password"
        label={t("login.Password")}
        password
        register={register("password")}
      />

      {passwordError !== undefined && (
        <Alert type="error">{passwordError}</Alert>
      )}

      {formError !== "" && <Alert type="error">{formError}</Alert>}

      <Buttons>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitted}
        >
          {t("login.Login")}
        </Button>
        <Button variant="minimal" onClick={() => navigate("/reset")}>
          {t("login.Forgot Password")}
        </Button>
      </Buttons>
    </form>
  );
}

export default LoginForm;
