import { point } from "@turf/helpers";
import { toMercator } from "@turf/projection";
import { toWgs84 } from "@turf/turf";
import axios, { AxiosError } from "axios";
import { Feature, MapBrowserEvent } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { GEO_TOOLBOX_URL } from "../../../config/aws";
import getCognitoToken from "../../../utils/getCognitoToken";
import { State } from "../interfaces";
import * as Sentry from "@sentry/react";
import { Geometry } from "ol/geom";
import {
  GeoserverWorkspaces,
  getGeoserverWorkspaces,
} from "../geoservers/GeoserverUtils";

/**
 * The shape of the response if we failed to get the statistics
 */
interface FailedQueryClickSelection {
  result: "failure";
  message: string;
}

/**
 * And the shape of the response if we did successfully get the data
 */
interface SuccessfulQueryClickSelection {
  result: "success";
  features: Array<Feature<Geometry>>;
  data: { [layerName: string]: { [name: string]: string } };
}

/**
 * Function for getting statistics when a user clicks on the map
 *
 * @param event The event generated by the users click
 * @param state The state of the map
 */
export default async function QueryClickSelection(
  event: MapBrowserEvent<UIEvent>,
  state: Readonly<State>
): Promise<SuccessfulQueryClickSelection | FailedQueryClickSelection> {
  if (state.times.active === undefined) {
    return { result: "failure", message: "Could not find the active time" };
  }

  if (state.layers.active.length === 0) {
    return { result: "failure", message: "Could not find the active layers" };
  }

  const workspaces = getGeoserverWorkspaces(state);
  const geojsons = createGeojsons(event, state, workspaces);

  const promises = [];
  for (const [geoserverId, workspace] of Object.entries(workspaces)) {
    if (geojsons[geoserverId] !== undefined) {
      promises.push(
        getStatistics(geoserverId, workspace, geojsons[geoserverId])
      );
    }
  }

  const promiseResults = await Promise.all(promises);

  let features: SuccessfulQueryClickSelection["features"] = [];
  let data: SuccessfulQueryClickSelection["data"] = {};
  const namesToIgnore = [
    "geojson",
    "id",
    "id_0",
    "fid",
    "distance",
    "layerName",
  ];

  for (const result of promiseResults) {
    try {
      if (result.result === "success") {
        for (const resultDataArray of result.data) {
          const resultData = resultDataArray[0];
          let feature = JSON.parse(resultData.geojson);
          feature.properties = {};
          feature = toMercator(feature);
          features.push(new GeoJSON().readFeature(feature));

          if (data[resultData.layerName] === undefined) {
            data[resultData.layerName] = {};
          }

          for (const [name, value] of Object.entries(resultData)) {
            if (namesToIgnore.indexOf(name) === -1) {
              data[resultData.layerName][name] = value;
            }
          }
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  if (features.length > 0) {
    return { result: "success", features: features, data: data };
  }

  return { result: "failure", message: "Unable to load the statistics" };
}

type selectionGeojsons = { [geoseverId: string]: any };

/**
 * Create all the geojson objects to indicate which statistics we want
 *
 * @param event The event generated by the users click
 * @param state The state of the map
 * @param workspaces The workspaces we know about
 * @returns A list of geojson objects for the statistics we want
 */
function createGeojsons(
  event: MapBrowserEvent<UIEvent>,
  state: Readonly<State>,
  workspaces: GeoserverWorkspaces
): selectionGeojsons {
  let geojsons: selectionGeojsons = {};

  for (const geoserverId of Object.keys(workspaces)) {
    let workspaceDate =
      state.times.active === "most_recent"
        ? state.times.mostRecentForGeoserverIdById[geoserverId]
        : state.times.active;

    if (workspaceDate !== undefined) {
      let geojson = point(event.coordinate);
      geojson = toWgs84(geojson);
      geojson.properties = {
        layerNames: state.layers.active,
        date: workspaceDate,
      };

      geojsons[geoserverId] = geojson;
    }
  }

  return geojsons;
}

type SelectionApiSuccessfulReturn = {
  result: "success";
  geoserverId: string;
  data: [[{ [name: string]: string }]];
};
type SelectionApiFailedReturn = { result: "failure"; message: string };

/**
 * Function for getting the statistics from the api
 *
 * @param geoserverId The geoserver id for which the statistics are requested
 * @param workspace The workspace for which to get the statistics
 * @param geojson The GeoJSON with the statistics to get
 * @returns The requested statistics or a error message
 */
async function getStatistics(
  geoserverId: string,
  workspace: string,
  geojson: any
): Promise<SelectionApiSuccessfulReturn | SelectionApiFailedReturn> {
  try {
    const token: unknown = await getCognitoToken();

    const response = await axios({
      method: "post",
      url: `${GEO_TOOLBOX_URL}/pickfeature?workspace=${workspace}`,
      data: geojson,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.result !== "success") {
      return { result: "failure", message: "Unable to load the statistics" };
    }

    return {
      result: "success",
      geoserverId: geoserverId,
      data: response.data.data,
    };
  } catch (err) {
    if (axios.isAxiosError(err) && err.response !== undefined) {
      const error = err as AxiosError;
      const data = error.response?.data as { message?: string };

      if (data.message !== undefined) {
        return { result: "failure", message: String(data.message) };
      }
    }

    return { result: "failure", message: "Unable to load the statistics" };
  }
}
