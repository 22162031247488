import bbox from "@turf/bbox";
import { multiPoint } from "@turf/helpers";
import { bboxPolygon, center } from "@turf/turf";
import Emittery from "emittery";
import { Feature, Overlay } from "ol";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OLMap from "ol/Map";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";
import View from "ol/View";
import tippy, { Instance, Props } from "tippy.js";
import Marker from "./marker.png";
import { Area } from "../../slices/areaSlice";
import { Geoserver } from "../../slices/geoserverSlice";

export default class OverviewMap {
  // The variables holding Open Layers-related classes
  private olMap: OLMap;
  private olView: View;
  // The areas to show
  private areas: {
    [areaId: string]: Area;
  };
  // Popover-related data
  private tooltipOverlay: Overlay;
  private tippyInstance: Instance<Props> | undefined;
  private tippyAreaId: number = 0;
  // The event emitter
  public emitter: Emittery;

  public constructor() {
    // Set up the event emitter
    this.emitter = new Emittery();

    // Set up the Open Layers classes
    const view = new View({
      center: [0, 0],
      zoom: 2,
      projection: "EPSG:3857",
    });

    this.olView = view;

    const map = new OLMap({
      target: "map",
      pixelRatio: 1, // to request same tile sizes
      // No controls since we're managing the zoom buttons manually
      controls: [],
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: view,
    });

    this.olMap = map;

    this.areas = {};

    // Handle the popover related setup
    let anchor: HTMLElement | null | undefined =
      document.getElementById("tooltipAnchor");
    if (anchor === null) {
      anchor = undefined;
    }

    const tooltipOverlay = new Overlay({
      element: anchor,
      positioning: "bottom-center",
      stopEvent: false,
    });

    this.olMap.addOverlay(tooltipOverlay);
    this.tooltipOverlay = tooltipOverlay;

    if (anchor !== undefined) {
      this.tippyInstance = tippy(anchor, {
        allowHTML: true,
        interactive: true,
        theme: "light-border",
        onShow: (instance) => {
          const node = instance.popper.querySelector("div");

          if (node !== null) {
            node.onclick = () => {
              this.emitter.emit("map:popoverClick", {
                areaId: this.tippyAreaId,
              });
            };
          }
        },
      });
    }

    // Set up a resize observer
    // TODO: apparently this isn't garbage collected when the class is no longer
    //referenced so we should manually remove it at some point
    const mapDiv = document.getElementById("map");
    if (mapDiv !== null) {
      const ro = new ResizeObserver(() => {
        this.olMap.updateSize();
      });
      ro.observe(mapDiv);
    }

    // Deal with a map click so we can the popover with the correct location and data
    this.olMap.on("click", (event) => {
      const feature = this.olMap.forEachFeatureAtPixel(
        event.pixel,
        function (feature) {
          return feature;
        }
      );

      if (feature !== undefined) {
        const point = feature.getGeometry() as Point;
        this.tooltipOverlay.setPosition(point.getCoordinates());

        const properties = feature.getProperties();

        if (this.tippyInstance !== undefined) {
          this.tippyAreaId = properties.areaId;
          this.tippyInstance.setContent(properties.name);
          this.tippyInstance.show();
        }
      }
    });
  }

  /**
   * Small function which adds the area data for the map
   *
   * @param areas The areas to add
   */
  public addAreas(areas: Array<Area>): void {
    for (const area of areas) {
      if (this.areas[area.id] === undefined) {
        this.areas[area.id] = area;
      }
    }
  }

  /**
   * Adds the geoservers so the user can see the imagery on the map
   * and the marker where the area is located
   *
   * @param geoservers The geoservers to add
   */
  public addGeoservers(geoservers: Array<Geoserver>): void {
    let points: Array<[number, number]> = [];

    const vectorSource = new VectorSource();

    const markerStyle = new Style({
      image: new Icon({
        src: Marker,
      }),
    });

    for (const geoserver of geoservers) {
      if (this.areas[geoserver.areaId] !== undefined) {
        points.push([geoserver.bbox[0], geoserver.bbox[1]]);
        points.push([geoserver.bbox[2], geoserver.bbox[3]]);

        const geoCenter = center(bboxPolygon(geoserver.bbox));

        const markerFeature = new Feature({
          geometry: new Point(geoCenter.geometry.coordinates),
          name: this.areas[geoserver.areaId].name,
          areaId: geoserver.areaId,
          namespace: geoserver.namespace,
        });
        markerFeature.setStyle(markerStyle);

        vectorSource.addFeature(markerFeature);
      }
    }

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 999,
    });

    this.olMap.addLayer(vectorLayer);

    const areaBbox = bbox(multiPoint(points));

    this.olView.fit(areaBbox as [number, number, number, number]);
  }

  /**
   * Function for zooming in
   */
  public zoomIn(): void {
    const currentZoom = this.olView.getZoom();

    if (currentZoom !== undefined) {
      this.olView.animate({ zoom: currentZoom + 1, duration: 250 });
    }
  }

  /**
   * Function for zooming out
   */
  public zoomOut(): void {
    const currentZoom = this.olView.getZoom();

    if (currentZoom !== undefined) {
      this.olView.animate({ zoom: currentZoom - 1, duration: 250 });
    }
  }
}
