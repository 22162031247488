import React from "react";

/**
 * Parses statistics coming in as JSON
 *
 * @param name The name of the statistics field
 * @param value The value of the statistics field
 */
function parseJson(name: string, value: string) {
  try {
    const parsed = JSON.parse(value);

    if (name.includes("_url")) {
      return (
        <React.Fragment>
          {Object.entries(parsed).map(([urlName, url], index) => (
            <React.Fragment key={index}>
              {index !== 0 ? ", " : ""}
              <a href={url as string} target="_blank" rel="noreferrer">
                {urlName}
              </a>
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
  } catch (e) {
    return value;
  }

  return value;
}

/**
 * Formats a statics value
 *
 * @param name The name of the statistics item
 * @param value The value of the statistics item
 * @return The formatted item
 */
export default function formatItem(
  name: string,
  value: React.ReactNode
): React.ReactNode {
  if (String(value)[0] === "{") {
    return parseJson(name, String(value));
  }

  if (!isNaN(Number(value))) {
    let formatted: string | number = Number(value);
    if (formatted % 1 !== 0) {
      formatted = formatted.toFixed(2);
    }

    if (name === "area_ha") {
      formatted = `${formatted} ha`;
    }

    return formatted;
  }

  try {
    const url = new URL(String(value));

    return (
      <a href={String(url)} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  } catch (e) {
    return value;
  }
}
