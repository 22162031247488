import {
  MainWithSidebarLayout,
  MapControlsContainer,
  MapToolsButtons,
  MapToolsNames,
  MapZoomButtons,
} from "@opusinsights/ui";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SingleAreaMap from "../../map/SingleAreaMap";
import { Events } from "../../map/SingleAreaMap/interfaces";
import { selectAreaByCompanyAndAreaSlugs } from "../../slices/areaSlice";
import { selectGeoserversByCompanyAndAreaSlugs } from "../../slices/geoserverSlice";
import { RootState } from "../../store";
import AreaSidebar from "./AreaSidebar";

/**
 * Parent component for showing a single area
 */
function Area() {
  const { companySlug, areaSlug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeTool, setActiveTool] =
    useState<undefined | MapToolsNames>(undefined);

  // Redirect the user back to the overview if either slug is missing
  if (companySlug === undefined || areaSlug === undefined) {
    navigate("/areas");
  }

  const geoservers = useSelector((state: RootState) =>
    selectGeoserversByCompanyAndAreaSlugs(
      state,
      companySlug as string,
      areaSlug as string
    )
  );
  const area = useSelector((state: RootState) =>
    selectAreaByCompanyAndAreaSlugs(
      state,
      companySlug as string,
      areaSlug as string
    )
  );
  const map = useRef<SingleAreaMap | null>(null);
  const [haveGeoserversBeenAdded, setHaveGeoserversBeenAdded] = useState(false);

  useLayoutEffect(() => {
    if (map.current === null) {
      map.current = new SingleAreaMap();
    }
  }, []);

  useEffect(() => {
    // If the slugs are there but there is no geoserver or area we can find for it we also redirect the user back to the overview
    if (geoservers.length === 0 || area === null) {
      navigate("/");
    }
  }, [geoservers, area, navigate]);

  useEffect(() => {
    if (!haveGeoserversBeenAdded && map.current !== null) {
      map.current.addGeoservers(geoservers);
      setHaveGeoserversBeenAdded(true);
    }
  }, [geoservers, haveGeoserversBeenAdded, map]);

  /**
   * Handle a click on the tools selection and dispatches the correct even into the map class
   *
   * @param tool The tool which has been selected
   */
  function changeTool(tool: MapToolsNames | undefined) {
    if (map.current !== null) {
      if (
        tool === "BoxSelection" ||
        (tool === undefined && activeTool === "BoxSelection")
      ) {
        map.current.emitter.emit(Events["interaction:toggle_box"]);
      }

      if (
        tool === "PolygonSelection" ||
        (tool === undefined && activeTool === "PolygonSelection")
      ) {
        map.current.emitter.emit(Events["interaction:toggle_polygon"]);
      }

      if (
        tool === "Measurement" ||
        (tool === undefined && activeTool === "Measurement")
      ) {
        map.current.emitter.emit(Events["interaction:toggle_measurement"]);
      }

      if (activeTool === tool) {
        setActiveTool(undefined);
      } else {
        setActiveTool(tool);
      }
    }
  }

  const mainContent = (
    <div id="map">
      <MapControlsContainer>
        <MapZoomButtons
          onZoomInClick={() => {
            if (map.current !== null) {
              map.current.zoomIn();
            }
          }}
          onZoomOutClick={() => {
            if (map.current !== null) {
              map.current.zoomOut();
            }
          }}
        />
        <MapToolsButtons
          texts={{
            tools: t("map.tools"),
            boxSelection: t("map.boxSelection"),
            polygonSelection: t("map.polygonSelection"),
            measurement: t("map.measurement"),
          }}
          activeTool={activeTool}
          onToolClick={changeTool}
        />
      </MapControlsContainer>
    </div>
  );

  const Sidebar =
    map.current !== null && area !== null ? (
      <AreaSidebar map={map.current} area={area} />
    ) : (
      <div />
    );

  return (
    <MainWithSidebarLayout mainContent={mainContent} sidebarContent={Sidebar} />
  );
}

export default Area;
