import { Nav } from "@opusinsights/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectActiveArea } from "../../slices/areaSlice";
import { processLogout } from "../../slices/authSlice";
import {
  MdDynamicFeed,
  MdExitToApp,
  MdLanguage,
  MdSettings,
} from "react-icons/md";

/**
 * The props for the AppNav component
 */
export interface AppNavProps {
  // Which icon to highlight in the nav
  activePage: string;
}

/**
 * The nav for the application
 *
 * @param props The props which the component expects
 */
export default function AppNav(props: AppNavProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeArea = useSelector(selectActiveArea);

  function handleClick(icon: string) {
    if (icon === "overview") {
      navigate("/");
    }
    if (icon === "area" && activeArea !== null) {
      navigate(`/area/${activeArea.companySlug}/${activeArea.nameSlug}`);
    }
    if (icon === "settings") {
      navigate("/settings");
    }
    if (icon === "logout") {
      dispatch(processLogout());
    }
  }

  return (
    <Nav
      icons={{
        primary: [
          {
            id: "overview",
            name: t("nav.Overview"),
            icon: MdDynamicFeed,
          },
          {
            id: "area",
            name: t("nav.Area"),
            icon: MdLanguage,
          },
        ],
        secondary: [
          {
            id: "settings",
            name: t("nav.Settings"),
            icon: MdSettings,
          },
          {
            id: "logout",
            name: t("nav.Logout"),
            icon: MdExitToApp,
          },
        ],
      }}
      activeIcon={props.activePage}
      onClick={(icon) => handleClick(icon)}
    />
  );
}
