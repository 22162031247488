import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { State } from "../interfaces";

dayjs.extend(utc);

type StatisticsData = { [name: string]: string };

/**
 * Returns the stored statistics data
 *
 * @param state The state of the map
 * @returns The statistics data and if it has been loaded or not
 */
export default function GetStatistics(state: Readonly<State>): {
  data: StatisticsData;
  loading: boolean;
} {
  if (state.times.active === undefined) {
    return {
      data: {},
      loading: state.statistics.loading,
    };
  }

  const allStatistics = getStatisticsForActiveTimeSeries(state);

  if (allStatistics.length === 0) {
    return {
      data: {},
      loading: state.statistics.loading,
    };
  }

  const mergedStatistics = mergeStatistics(allStatistics);

  return {
    data: mergedStatistics,
    loading: state.statistics.loading,
  };
}

/**
 * Retrieves all the statistics for the current active time series
 *
 * @param state The state of the map
 * @returns A array of the statistics from the different workspaces
 */
function getStatisticsForActiveTimeSeries(state: State): Array<StatisticsData> {
  let allStatistics: Array<StatisticsData> = [];
  let dates: Array<string> = [];
  let geoserverIds: Array<string> = [];

  if (state.times.active === "most_recent") {
    for (const [time, timeGeoserverIds] of Object.entries(
      state.times.mostRecentForGeoserverIdByTime
    )) {
      dates.push(dayjs(time).utc().format("YYYY-MM-DD"));
      geoserverIds = [...geoserverIds, ...timeGeoserverIds];
    }
  } else {
    dates.push(dayjs(state.times.active).utc().format("YYYY-MM-DD"));
  }

  for (const date of dates) {
    if (state.statistics.byTimeGeoserverIdAndName[date] !== undefined) {
      for (const [geoserverId, statistics] of Object.entries(
        state.statistics.byTimeGeoserverIdAndName[date]
      )) {
        if (
          geoserverIds.length === 0 ||
          geoserverIds.indexOf(geoserverId) !== -1
        ) {
          allStatistics.push(statistics);
        }
      }
    }
  }

  return allStatistics;
}

/**
 * Small function to merge statistics from different workspaces / timeseries
 *
 * @param allStatistics The statistics to merge
 * @returns The merged statistics
 */
function mergeStatistics(allStatistics: Array<StatisticsData>): StatisticsData {
  let merged: StatisticsData = {};

  for (const statistics of allStatistics) {
    for (const [name, value] of Object.entries(statistics)) {
      if (merged[name] === undefined) {
        merged[name] = value;
      } else {
        merged[name] = String(Number(merged[name]) + Number(value));
      }
    }
  }

  return merged;
}
